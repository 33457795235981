const OTP_SERVER_ENDPOINT = process.env.REACT_APP_OTP_SERVER_ENDPOINT;

const pad = (string, length, padString="0") => {
    return ("" + string).padStart(length, padString);
};

const getDateTime = (date, time) => {
    let tokens = [];
    date = date ? date : new Date();
    tokens.push(`${date.getFullYear()}-${pad(date.getMonth()+1,2)}-${pad(date.getDate(),2)}`);
    time = time ? time : new Date(new Date().setHours(0,0,0,0));
    tokens.push(`T${pad(time.getHours(), 2)}:${pad(time.getMinutes(),2)}:${pad(time.getSeconds(),2)}+02:00`);
    return tokens.join("");
};

const transportTypeQuery = (transportType) => {
    return `{ mode: "${transportType.mode}" include: ${transportType.include} }`;
};

const TransportTypesQuery = (transportTypes, join=" ") => 
    transportTypes.map(
        (transportType) => transportTypeQuery(transportType)
    )
    .join(join)
;

const EasyQuery = async ({
    //dateTime="2021-08-10T07:38:53+02:00",
    walkSpeed=1.3,
    arriveBy=false,
    from,
    to,
    numTripPatterns=10,
    transportTypes=[]
}) => {

    
    let transportTypesText = TransportTypesQuery(transportTypes);

    let dateTime = null;
    //let dateTime = getDateTime(new Date());
    let response = await fetch(OTP_SERVER_ENDPOINT, 
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: `
            {
                easyDirections(
                    ${(from ? 
                        `from:{
                            coordinates	:{
                                latitude: ${from.latitude}
                                longitude: ${from.longitude}
                            }
                        }`
                    : "")}
                    ${(to ? 
                        `to:{
                            coordinates	:{
                                latitude: ${to.latitude}
                                longitude: ${to.longitude}
                            }
                        }`
                    : "")}
                    transportTypes: [${transportTypesText}]
                    ${(numTripPatterns ? `numTripPatterns: ${numTripPatterns}` : "")}
                    ${(dateTime ? `dateTime: "${dateTime}"` : "")}
                    ${(walkSpeed ? `walkSpeed: ${walkSpeed}` : "")}
                    ${(arriveBy !== undefined ? `arriveBy: ${arriveBy}` : "")}
                ) {
                    agencies { name }
                    currencies { code, fractionDigits }
                    suggestedTransports {
                        mode
                        agency { reference, name }
                        duration { 
                            minimum { minutes } 
                            maximum { minutes } 
                        }
                        fare { 
                            minimum
                            unknown
                            free
                            maximum
                            currency { reference } 
                        }
                        suggestedRoute { reference }
                    }
                    suggestedRoutes {
                        carbonEmissions
                        start
                        end
                        duration { minutes }
                        fare {
                            unknown
                            free
                            value
                            currency { reference }
                        }
                        legs {
                            from { name, platformLine }
                            to { name, platformLine }
                            name
                            agency { reference, name }
                            mode
                            polyline
                            fare {
                                value
                                currency { reference }
                            }
                            duration { minutes }
                            carbonEmissions
                            stops {
                                location {
                                  name
                                  coordinates { latitude longitude }
                                }
                            }
                        }
                        directions {
                            mode
                            duration { minutes }
                            from {
                                name
                                platformLine
                                coordinates { latitude longitude }
                            }
                            to {
                                name
                                platformLine
                                coordinates { latitude longitude }
                            }
                            steps {
                                direction { relative absolute },
                                location {
                                    coordinates { latitude longitude },
                                    name
                                    area
                                    bogusName
                                }
                                stayOn
                                distance
                            }
                        }
                    }
                }
            }
            `})
        });
    let json = await response.json();
    return json;
};
export { EasyQuery, TransportTypesQuery };