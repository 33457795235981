import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { SettingsQuery } from "./SettingsQuery.js";

const GetTravelMode = (transportType) => {
    let travelMode = null;
    switch(transportType.mode) {
        case "BICYCLE_RENT": travelMode = "Bike (rental)"; break;
        case "BICYCLE": travelMode = "Bike"; break;
        case "BUS": travelMode = "Bus"; break;
        case "WALK": travelMode = "Foot"; break;
        case "CAR": travelMode = "Car"; break;
        case "TRAM": travelMode = "Tram"; break;
        case "SUBWAY": travelMode = "Subway"; break;
        default:
            travelMode = transportType.mode;
            break;
    }
    if(transportType && transportType.agency && transportType.agency.name) {
        travelMode = transportType.agency.name;
    }
    return travelMode;
};

const LoopOverResults = (results, tab) => {
    let lines = [];
    if(results?.length > 0) {
        results.forEach((transportType, index) => {

            lines.push(`${tab}...`);
            lines.push(`${tab}/*\n${tab}${JSON.stringify(transportType, null, 4).replace(/\n/g, "\n" + tab)}*/`);
            lines.push(`${tab}let transportType = transportTypes[${index}];`);
            
            lines.push(`${tab}let icon = transportType.mode; // ${transportType.mode}`);
            lines.push(`${tab}let travel_mode = "By " + GetTravelMode(transportType); // By ${GetTravelMode(transportType)}`);
            //lines.push(`${tab}let duration = GetDuration(transport.duration) + " min"; // ${GetDuration(suggestedTransport.duration)} min`);
            //lines.push(`${tab}let fare = GetFare(transport.fare, result.currencies); // ${GetCostString(suggestedTransport.fare, results.currencies)}`);
            
        });
    }
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, results, transportType, index) => {
    //let travelMode = GetTravelMode(suggestedTransportItem.mode, suggestedTransportItem?.agency, directionsResult.agencies);

    return (
    <div key={JSON.stringify(transportType)} className="directions-transport-types-item" onClick={onSelect.bind(null, transportType, index)}>
        {<span className="middle">icon:<small>{transportType.mode}</small> </span>}
        <span className="middle">
            By {GetTravelMode(transportType)}
        </span>
        <span className="middle">
            {
                transportType.include === true
                ? <small>SELECTED</small>
                : <small>.</small>
            }
        </span>
    </div>
)};

const Markdown = (results) => { 

    let relevantResults = results.map((item) => {
        return {
            mode: item.mode,
            agency: item.agency
        };
    });

    return `
# Suggested Transports

The data on the left was retrieved using the following GraphQL query:

\`\`\`graphql
{
    settings
    {
        availableTransportTypes
        {
            mode
            agency { name }
        }
    }
}
\`\`\`

The query above produced this result:
\`\`\`json
${JSON.stringify(relevantResults, null, 4)}
\`\`\`

**Calculating the travel mode:**
\`\`\`js
GetTravelMode => ${GetTravelMode}
\`\`\`


**Looping over the result:**
\`\`\`js
let transport;
for(let i = 0; i < transports.length; i++) {
${LoopOverResults(relevantResults, "\t")}    
}
\`\`\`

---

.
`;};

const TransportTypes = ({
    onChange,
}) => {

    let [currentResults, setCurrentResults] = React.useState([]);

    // initialize
    useEffect(async () => {
        let settings = await SettingsQuery();
        let result = settings?.data?.settings?.availableTransportTypes;
        result.forEach((r) => { r.include = true; });
        if(result) {
            setCurrentResults(result);
            onChange(result);
        }
    }, []);

    const onItemSelect = (item, index) => {
        item.include = !item.include;
        let copy = JSON.parse(JSON.stringify(currentResults));
        copy[index] = item;
        setCurrentResults(copy);
        onChange(copy);
    };

    return (
        currentResults?.length > 0 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(currentResults)} headerText="Transport Types (modes)" className="directions-transport-types-container">
                    { currentResults.map(RenderItems.bind(null, onItemSelect, currentResults)) }
                </ExampleCode>
            )
        : null
    );
};

export default TransportTypes;