import React, { useEffect } from 'react';
import Lines from "./Lines.js";
import LinesOnMap from "./LinesOnMap.js";
import Line from "./Line.js";
import StationsOnMap from "./StationsOnMap.js";
import Station from "./Station.js";
/*
import Stations from "./Stations.js";
import Timetable from "./Timetable.js";
import Additional from "./Additional.js";
import StationsOnMap from "./StationsOnMap.js";
*/
import FilterTypes from "./FilterTypes.js";
import QueryLimits from "./QueryLimits.js";

const EasyLines = ({
    results,
    lineResults,
    stationResults,
    origin,
    onSelectLine,
    onSelectStation,
    mapState,
    onFilterType,
    onQueryLimits,
    searchRadius
}) => {

    let [filterType, setFilterType] = React.useState([]);
    const _onFilterType = (filterType) => {
        setFilterType(filterType);
        if(onFilterType) {
            onFilterType(filterType);
        }
    };

    let [queryLimits, setQueryLimits] = React.useState(null);
    const _onQueryLimits = (queryLimits) => {
        setQueryLimits(queryLimits);
        if(onQueryLimits) {
            onQueryLimits(queryLimits);
        }
    };

    const [selectedLine, setSelectedLine] = React.useState(null);
    const _onSelectLine = (line) => {
        setSelectedLine(line);
        if(onSelectLine) {
            onSelectLine(line);
        }
        mapState.setSelectedLine(line);
    };

    const [selectedStation, setSelectedStation] = React.useState(null);
    const _onSelectStation = (station) => {
        setSelectedStation(station);
        if(onSelectStation) {
            onSelectStation(station);
        }
        mapState.setSelectedLineStation(station);
    };

    return (
        <div>
            <QueryLimits onChange={_onQueryLimits} inputRadius={searchRadius}/>
            <FilterTypes onChange={_onFilterType}/>
            <Lines onSelect={_onSelectLine} results={results} origin={origin} lineFilter={filterType} queryLimits={queryLimits}/>
            <LinesOnMap results={results} mapState={mapState} selectedLine={selectedLine}/>
            <Line onSelect={_onSelectStation} results={lineResults} origin={origin} selectedLine={selectedLine}/>
            <StationsOnMap results={lineResults} mapState={mapState} selectedStation={selectedStation}/>
            <Station results={stationResults} mapState={mapState} selectedStation={selectedStation} origin={origin}/>
            {/*
            <Stations onSelect={_onSelectStation} results={results} origin={origin} stationFilter={filterType} queryLimits={queryLimits}/>
            <Timetable results={results} origin={origin} selectedStation={selectedStation}/>
            <Additional results={results} origin={origin} selectedStation={selectedStation}/>
            <StationsOnMap results={results} mapState={mapState} selectedStation={selectedStation}/>
            */}
        </div>
    );
};

export default EasyLines;