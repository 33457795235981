import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const GetDistance = (distance) => {
    let tokens = [];
    if(distance.km > 0) { tokens.push(`${distance.km} km`); }
    if(distance.m > 0) { tokens.push(`${distance.m} m`); }
    return tokens.join(" ");
};

const GetDuration = (duration) => {

    let tokens = [];
    if(duration?.formatted != null) {
        if(duration.formatted.hours > 0) { tokens.push(`${duration.formatted.hours} hr`); }
        if(duration.formatted.minutes > 0) { tokens.push(`${duration.formatted.minutes} min`); }
        if(duration.formatted.seconds > 0) { tokens.push(`${duration.formatted.seconds} sec`); }
    }
    return tokens.join(" ");
};

const GetInterval = (interval) => {

    if(interval?.unknown === true) {
        return "Interval Unknown";
    }
    let tokens = [];
    if(interval?.formatted != null) {
        if(interval.formatted.hours > 0) { tokens.push(`${interval.formatted.hours} hr`); }
        if(interval.formatted.minutes > 0) { tokens.push(`${interval.formatted.minutes} min`); }
        if(interval.formatted.seconds > 0) { tokens.push(`${interval.formatted.seconds} sec`); }
    }
    return `Every ${tokens.join(" ")}`;
};

const GetType = (type, agency, agencies) => {
    let result = null;
    let agencyReference = null;
    switch(type) {
        case "BUS": result = "Bus"; break;
        case "TRAM": result = "Tram"; break;
        default:
            result = type;
            break;
    }

    if(agencyReference !== null) {
        result = agencies[agencyReference].name;
    }
    return result;
};

const LoopOverResults = (results, tab) => {
    try {
    let lines = [];
    
    results.filtered.forEach((_filtered, index) => {

        let line = _filtered;

        lines.push(`${tab}...`);
        
        lines.push(`${tab}/*\n${tab}${JSON.stringify(_filtered, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let line = filtered[${index}]; // see json above`);
        lines.push(`${tab}let iref = line.iref; // ${line.iref}`);
        lines.push(`${tab}let icon = line.type; // ${line.type}`);
        lines.push(`${tab}let type = GetType(line.type, line.agency, agencies); // ${GetType(line.type, line.agency, results.agencies)}`);
        lines.push(`${tab}let name = line.name; // ${line.name}`);
        lines.push(`${tab}let address = line.location.address; // ${line.location.address}`);
        lines.push(`${tab}let duration = GetDuration(line.duration); // ${GetDuration(line.duration)}`);
        lines.push(`${tab}let distance = GetDistance(line.distance); // ${GetDistance(line.distance)}`);
        lines.push(`${tab}let polyline = line.polyline; // ${line.polyline}`);
    });
    return lines.join("\n");
    } catch(err) {
        console.error(err);
        return ``;
    }
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, results, filterItem,) => {

    let lineItem = filterItem;
    let name = lineItem.name;
    let address = lineItem.location?.address;

    return (
    <div key={JSON.stringify(lineItem)} className="lines-item" onClick={onSelect.bind(null, lineItem)}>
        <span className="left">
            <nobr>
            icon:<small>{lineItem.type}</small> <b>{name}</b>
            </nobr>
        </span>
        <span className="right">
        <nobr>
            {`${lineItem.distance.km > 0 ? `${lineItem.distance.km} km ` : ''}${lineItem.distance.m} m`}
            </nobr>
        </span>
        <span className="left">
            <nobr>
            {address}
            </nobr>
        </span>
        <span className="right">
            <nobr>
            {GetDuration(lineItem.duration)}
            </nobr>
        </span>
    </div>
)};

const Markdown = (originString, filter, queryLimits, results) => { 

    let origin = originString.split(", ");
    let relevantResults = {
        agencies: results.agencies,
        filtered: []
    };
    results.filtered.forEach((station) => {
        relevantResults.filtered.push(station);
    });
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Routes

The data on the left was retrieved using the following GraphQL query:

\`\`\`graphql
{
    easyLines(
        coordinates: {
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        }${
        (filter?.type ?
        `,
        filter: {
            type: "${filter.type}"
        }`: "")
        }${
        (queryLimits?.radius ?
        `,
        queryLimits: {
            radius: { ${queryLimits.radius_mode === "metric" ? `m: ${queryLimits.radius.m}, km: ${queryLimits.radius.km}` : `ft: ${queryLimits.radius.ft}, mi: ${queryLimits.radius.mi}`} },
            filter: ${queryLimits.filter},
            general: ${queryLimits.general}
        }`
        : "")}
    ) {
        filtered {
            iref
            polyline
            name
            location { address }
            distance { m, km }
            duration { formatted { minutes seconds } }
            type
            agency { name }
        }
    }
}
\`\`\`

The query above produced this result:

\`\`\`json
${relevantJson}
\`\`\`

**Get the type label:**
\`\`\`js
GetType => ${GetType}
\`\`\`

**Calculating the distance (string):**
\`\`\`js
GetDistance => ${GetDistance}
\`\`\`

**Calculating the duration:**
\`\`\`js
GetDuration => ${GetDuration}
\`\`\`

**Looping over the result:**
\`\`\`js
for(let i = 0; i < filtered.length; i++) {
${LoopOverResults(results, "\t")}    
}
\`\`\`

---
.
`;};

const Lines = ({
    results,
    origin,
    lineFilter,
    queryLimits,
    onSelect,
}) => {

    let [currentResults, setCurrentResults] = React.useState(results);

    useEffect(() => {
        setCurrentResults(results);
    }, [results]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        onSelect(item);
    };

    return (
        results?.filtered?.length > 0 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(origin, lineFilter, queryLimits, results)} headerText="Routes" className="lines-container">
                    { results?.filtered?.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default Lines;