import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const GetCostString = (fare, currencies) => {
    
    if(fare?.currency?.reference == null || currencies == null) { 
        console.error("not found!");
        return ""; 
    }

    if(fare?.unknown === true) {
        return `Unknown`;
    }
    if(fare?.free === true) {
        return `Free`;
    }

    let currency = currencies[fare.currency.reference];
    let digits = currency.fractionDigits;
    let div = Math.pow(10, digits);

    return `${currency.code} ${Number(fare.value / div).toFixed(digits)}`;
}

const GetTravelModes = (suggestedRoute) => {
    return suggestedRoute.legs.map(
        (leg) => leg.from.platformLine ? `${leg.mode}: ${leg.from.platformLine}` : leg.mode
    ).join(", ");
};

const LoopOverResults = (suggestedRoutes, results, tab) => {
    let lines = [];
    suggestedRoutes.forEach((suggestedRoute, index) => {
        //let legs = suggestedRoute.legs.map((leg) => { return { mode: leg.mode, name: leg.name }; });
        let route = {
            carbonEmissions: suggestedRoute.carbonEmissions,
            mode: suggestedRoute.mode,
            duration: suggestedRoute.duration,
            fare: suggestedRoute.fare,
            legs: suggestedRoute.legs,
            start: suggestedRoute.start,
            end: suggestedRoute.end
        };
        lines.push(`${tab}...`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(route, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let route = routes[${index}];`);
        
        lines.push(`${tab}let travel_modes = GetTravelModes(route); // ${GetTravelModes(route)}`);
        lines.push(`${tab}let duration = \`\${route.duration.minutes} min\`; // ${route.duration.minutes} min`);
        //lines.push(`${tab}let travel_mode = "By " + GetTravelMode(transport.mode, transport.agency, result.agencies); // By ${GetTravelMode(suggestedTransport.mode, suggestedTransport.agency, results.agencies)}`);
        lines.push(`${tab}let carbonEmissions = \`\${route.carbonEmissions} g/km\`; // ${route.carbonEmissions} g/km`);
        lines.push(`${tab}let fare = GetFare(route.fare, result.currencies); // ${GetCostString(route.fare, results.currencies)}`);
        try {
            lines.push(`${tab}let start = new Date(route.start); // start(${route.start}) -> ${new Date(route.start)}`);
            lines.push(`${tab}let end = new Date(route.end); // end(${route.end}) -> ${new Date(route.end)}`);
        } catch(err) {
            console.error(err);
        }
    });
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, directionsResult, suggestedRoute,) => {
    suggestedRoute._key = __key++;
    return (
    <div key={JSON.stringify(suggestedRoute)} className="directions-suggested-routes-item" onClick={onSelect.bind(null, suggestedRoute)}>
        <span className="left"><small>{GetTravelModes(suggestedRoute)}</small></span>
        <span className="right">{suggestedRoute.duration.minutes} min</span>
        <span className="left"><small>CO2 {suggestedRoute.carbonEmissions}g/km</small></span>
        <span className="right">{GetCostString(suggestedRoute.fare, directionsResult.currencies)}</span>
    </div>
)};

const Markdown = (originString, destinationString, results, suggestedRoutes, transportTypes) => { 
    let origin = originString.split(", ");
    let destination = destinationString.split(", ");
    
    let relevantResults = [];
    suggestedRoutes.forEach((suggestedRoute) => {
        let route = {
            carbonEmissions: suggestedRoute.carbonEmissions,
            start: suggestedRoute.start,
            end: suggestedRoute.end,
            duration: suggestedRoute.duration,
            fare: suggestedRoute.fare,
            legs: []
        };
        suggestedRoute.legs.forEach((leg) => {
            route.legs.push({
                mode: leg.mode, 
                from: leg.from,
                to: leg.to,
            });
        });
        relevantResults.push(route);
    });
    let transportTypesText = TransportTypesQuery(transportTypes, "\n\t\t\t");
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Suggested Routes

The data on the right is a sub selection produced by the following GraphQL query:
> Please note:
> that only relevant data is displayed; an ellipsis is used indicate missing fields.

> Please note:
> it is now possible to select suggested routes hierarchically linked to a suggested transport
> and / or select all suggested routes.

\`\`\`graphql
{
	easyDirections(
	    ...
        arriveBy: false """ defaults to false """
    	date: null      """ format: YYYY-MM-DD (defaults to today) """
    	time: null      """ format: HH:mm (24 hour format, defaults to now) """
        ...
	    from: { coordinates: { 
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        } }
        to: { coordinates:{ 
            latitude: ${destination[1]}
            longitude: ${destination[0]}
        } }
        transportTypes: [
            ${transportTypesText}
        ]
    ) {
        agencies { name }
        currencies { code fractionDigits }
        suggestedTransports {
            """ selecting the suggested routes here would result in the hierarchically linked suggested routes """
            suggestedRoutes { 
                ...
            }
        }
        """ selecting the suggested routes here would result in all suggested routes """
        suggestedRoutes {
            carbonEmissions
            start
            end
            duration { minutes }
            fare { 
                unknown
                free
                value
                currency { reference } 
            }
            legs { 
                from { name, platformLine }
                to { name, platformLine }
                agency { reference, name }
                mode 
                polyline 
                fare { 
                    unknown
                    free
                    value 
                    currency { reference } 
                }
                duration { minutes }
            }
        }
    }
}
\`\`\`

The query above, and sub selection, produced this result:
\`\`\`json
${relevantJson}
\`\`\`

**Object references:**

Some object types are referencable; they are included as a top level array where the reference field is a numeric index.
The following object types are referenced here:
 * currency

> Using this method is opt-in and you may choose to include the fields of the referenced objects directly.

**Calculating the fare:**
\`\`\`js
GetFare => ${GetCostString}
\`\`\`

**Calculating the travel modes**
\`\`\`js
GetTravelModes => ${GetTravelModes}
\`\`\`

**Looping over the result:**
\`\`\`js
for(let i = 0; i < routes.length; i++) {
${LoopOverResults(suggestedRoutes, results, "\t")}    
}
\`\`\`

.
`;};

const SuggestedRoutes = ({
    results,
    origin,
    destination,
    suggestedRoutes,
    onSelect,
    transportTypes,
}) => {

    let [currentResults, setCurrentResults] = React.useState(suggestedRoutes);

    useEffect(() => {
        setCurrentResults(suggestedRoutes);
    }, [suggestedRoutes]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        setSelectedIndex(index);
        onSelect(item);
    };

    return (
        currentResults?.length > 0 
        ?   (
                <ExampleCode showCodeTo="left" markdown={Markdown(origin, destination, results, suggestedRoutes, transportTypes)} headerText="Suggested Routes" className="directions-suggested-routes-container">
                    { currentResults.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default SuggestedRoutes;