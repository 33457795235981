const OTP_SERVER_ENDPOINT = process.env.REACT_APP_OTP_SERVER_ENDPOINT;

const SettingsQuery = async () => {
    let response = await fetch(OTP_SERVER_ENDPOINT, 
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: `
            {
                settings
                {
                    availableLineFilters
                    {
                        type
                        agency { name }
                    }
                    defaultLineQueryLimits
                    {
                        radius { m, km }
                        filter
                        general
                    }
                }
            }
            `})
        });
    let json = await response.json();
    return json;
};
export { SettingsQuery };