const OTP_SERVER_ENDPOINT = process.env.REACT_APP_OTP_SERVER_ENDPOINT;

const Lines = async ({
    coordinates,
    filter,
    queryLimits
}) => {

    let query = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: `
        {
            easyLines(
                ${(coordinates ? 
                    `coordinates: {
                        latitude: ${coordinates.latitude}
                        longitude: ${coordinates.longitude}
                    }`
                : "")}
                ${(coordinates && filter?.type ? ',' : '')}
                ${(filter?.type ? 
                    `filter: {
                        type: "${filter.type}"
                    }`
                : "")}${
                (queryLimits?.radius ?
                    `,
                    queryLimits: {
                        radius: { m: ${queryLimits.radius.m > 0 ? queryLimits.radius.m : 0}, km: ${queryLimits.radius.km > 0 ? queryLimits.radius.km : 0}, ft: ${queryLimits.radius.ft > 0 ? queryLimits.radius.ft : 0}, mi: ${queryLimits.radius.mi > 0 ? queryLimits.radius.mi : 0} },
                        filter: ${queryLimits.filter},
                        general: ${queryLimits.general}
                    }`
                : "")}
            ) {
                filtered {
                    iref
                    polyline
                    name
                    location { address }
                    distance { m, km }
                    duration { formatted { minutes seconds } }
                    type
                    agency { name }
                }
            }
        }
        `})
    };

    let response = await fetch(OTP_SERVER_ENDPOINT, query);
    let json = await response.json();
    return json;
};

const Line = async ({
    coordinates,
    line
}) => {

    let query = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: `
        {
            easyLines(
                ${(coordinates ? 
                    `coordinates: {
                        latitude: ${coordinates.latitude}
                        longitude: ${coordinates.longitude}
                    }`
                : "")}
                line: "${line}"
            ) {
                filtered {
                    iref
                    polyline
                    name
                    location { address }
                    distance { m, km }
                    duration { formatted { minutes seconds } }
                    type
                    agency { name }
                    stations {
                        iref
                        type
                        location { address, coordinates { latitude, longitude } }
                        distance { m, km }
                    }
                }
            }
        }
        `})
    };

    let response = await fetch(OTP_SERVER_ENDPOINT, query);
    let json = await response.json();
    return json;
};

const Station = async ({
    coordinates,
    station
}) => {

    console.log(`Search/EasyLines/Station: station=${station.iref}`);

    let query = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: `
        {
            easyLines(
                ${(coordinates ? 
                    `coordinates: {
                        latitude: ${coordinates.latitude}
                        longitude: ${coordinates.longitude}
                    }`
                : "")}
                station: "${station.iref}"
            ) {
                filtered {
                    iref
                    name
                    location { address }
                    distance { m, km }
                    duration { formatted { minutes seconds } }
                    type
                    agency { name }
                    polyline
                    stations {
                        iref
                        reference
                        location { address }
                        distance { m, km }
                        duration { formatted { minutes seconds } }
                        agency { reference }
                        interval {
                            formatted { hours minutes seconds }
                            unknown
                        }
                        timetable {
                            departureTime {
                                formatted {
                                    hours,
                                    minutes,
                                    seconds,
                                    isPM
                                }
                            }
                            location {
                                name
                            }
                        }
                    }
                }
            }
        }
        `})
    };

    let response = await fetch(OTP_SERVER_ENDPOINT, query);
    let json = await response.json();

    console.log(`response: `);
    console.log(json);

    return json;
};

export { Lines, Line, Station };