import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import "github-markdown-css";
//import SuggestedTransports from "./SuggestedTransports.js";

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/prism'

const ExampleCode = (props) => {
    const headerText = props.headerText;
    const markdown = props.markdown;
    
    const directionsResult = props.directionsResult;
    const onSelect = props.onSelect;

    const getShowTo = () => {
        let showTo = props.showCodeTo;
        switch(props.showCodeTo) {
            case "left": return "example-code-view__left"; 
            case "top": return "example-code-view__top";
            case "bottom": return "example-code-view__bottom";
            case "right":
            default:
                case "top": return "example-code-view__right";
        }
    };

    const getClassNames = () => {
        return ["example-code-view", "markdown-body", getShowTo()].join(" ");
    }

    let [currentResults, setCurrentResults] = React.useState(directionsResult);
    let [isShowCode, setIsShowCode] = React.useState(false);
    let [isShowContent, setIsShowContent] = React.useState(true);

    useEffect(() => {
        setCurrentResults(directionsResult);
    }, [directionsResult]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        setSelectedIndex(index);
        onSelect(item);
    };


    const onViewCode = () => {
        setIsShowCode(true);
    };

    const onHideCode = () => {
        setIsShowCode(false);
    };

    const onShowContent = () => {
        setIsShowContent(true);
    };

    const onHideContent = () => {
        setIsShowContent(false);
    };

    return (
        <div>
            <div className={props.className}>
                <h4 className="example-code-header">
                    { headerText }
                    {
                        isShowContent === false
                        ? <button onClick={onShowContent}>Show</button>
                        : null
                    }
                    {
                        isShowContent === true
                        ? <button onClick={onHideContent}>Hide</button>
                        : null
                    }
                </h4>
                {
                    isShowContent === true
                    ?
                        <div className="example-code-widget-ui">
                            { props.children }
                        </div>
                    : null
                }
                {
                    isShowCode === false &&
                    isShowContent === true
                    ?
                    <div className="example-code-open-code">
                        <button onClick={onViewCode}>View Code</button>
                    </div>
                    : null
                }
            </div>

            {
                isShowCode === true &&
                isShowContent === true
                ?
                    <div className={getClassNames()}>
                        <button className="example-code-close-button" onClick={onHideCode}>Close Code View</button>
                        <ReactMarkdown
                            children={markdown}
                            remarkPlugins={[remarkGfm]}
                            components={{
                            code({node, inline, className, children, ...props}) {
                                const match = /language-(\w+)/.exec(className || '')
                                return !inline && match ? (
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={a11yDark}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                />
                                ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                                )
                            }
                            }}
                        />
                    </div>
                :   null
            }

        </div>
    );
};

export default ExampleCode;