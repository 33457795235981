const OTP_SERVER_ENDPOINT = process.env.REACT_APP_OTP_SERVER_ENDPOINT;

const Geocode = async ({
    query
}) => {
    let response = await fetch(OTP_SERVER_ENDPOINT, 
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: `
            {
                geocode(
                    forward: {
                        query: "${query}"
                    }
                ) {
                    results {
                        place_type
                        text
                        place_name
                        address
                        category
                        latitude
                        longitude
                    }
                }
            }
            `})
        });
    let json = await response.json();
    return json;
};
export { Geocode };