import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const PadLeft = (length, str) => {
    if(length - (''+str).length < 0) { return str; }
    let pad = new Array(length - (''+str).length);
    pad.fill('0', 0, pad.length);
    return `${pad.join('')}${str}`;
};

const GetTime = (time) => {
    if(time == null) { return ""; }
    return `${PadLeft(2,time.formatted.hours)}:${PadLeft(2,time.formatted.minutes)} ${time.formatted.isPM ? 'pm' : 'am'}`;
};

const GetInterval = (interval) => {
    if(interval?.unknown === true) {
        return "Interval Unknown";
    }
    let tokens = [];
    if(interval?.formatted != null) {
        if(interval.formatted.hours > 0) { tokens.push(`${interval.formatted.hours} hr`); }
        if(interval.formatted.minutes > 0) { tokens.push(`${interval.formatted.minutes} min`); }
        if(interval.formatted.seconds > 0) { tokens.push(`${interval.formatted.seconds} sec`); }
    }
    return `Every ${tokens.join(" ")}`;
};

const GetType = (type, agency, agencies) => {
    let result = null;
    let agencyReference = null;
    switch(type) {
        case "BICYCLE_RENT": 
        case "PURPLE_POINT":
            result = agency?.name; 
            agencyReference = agency?.reference;
            break;
        case "BICYCLE": result = "Bike"; break;
        case "BUS": result = "Bus"; break;
        case "WALK": result = "Foot"; break;
        case "CAR": result = "Car"; break;
        case "TRAM": result = "Tram"; break;
        default:
            result = type;
            break;
    }

    if(agencyReference !== null) {
        result = agencies[agencyReference].name;
    }
    return result;
};

const LoopOverResults = (results, selectedStation, tab) => {
    let lines = [];
    
    selectedStation.timetable.forEach((entry, index) => {
        let result = {
            departureTime: entry.departureTime,
            location: entry.location,
        };
        lines.push(`${tab}...`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(result, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let entry = timetable[${index}];`);
        
        lines.push(`${tab}let name = entry.location.name; // ${entry.location.name}`);
        lines.push(`${tab}let time = GetTime(entry.departureTime); // ${GetTime(entry.departureTime)}`);
    });
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, results, selectedStation, timetableItem,) => {

    return (
    <div key={`JSON.stringify(timetableItem)${__key++}`} className="stations-timetable-item">
        <span className="left">
            {timetableItem.location.name}
        </span>
        <span className="right">
            {GetTime(timetableItem.departureTime)}
        </span>
    </div>
)};

const Markdown = (originString, results, selectedStation) => { 

    let origin = originString.split(", ");
    let timetable = selectedStation.timetable.map((entry) => {
        return {
            departureTime: entry.departureTime,
            location: entry.location,
        };
    });

    let station = {
        location: selectedStation.location,
        agency: selectedStation.agency,
        timetable,
    };

    let relevantResults = {
        agencies: results.agencies,
        stations: [station]
    };

    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Timetable

The data on the right was retrieved using the following GraphQL query:

\`\`\`graphql
{
    easyStations(
        coordinates: {
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        }
    ) {
        agencies { name }
        stations {
            agency { reference }
            timetable {
                departureTime {
                    formatted {
                        hours,
                        minutes,
                        seconds,
                        isPM
                    }
                }
                location {
                    name
                }
            }
            location {
                name
                platformLine
            }
            ...
        }
    }
}
\`\`\`

The query above produced this result:
\`\`\`json
${relevantJson}
\`\`\`

**Object references:**

Some object types are referencable; they are included as a top level array where the reference field is a numeric index.
The following object types are referenced here:
 * agency

> Using this method is opt-in and you may choose to include the fields of the referenced objects directly.

**Padding:**
\`\`\`js
PadLeft => ${PadLeft}
\`\`\`

**Calculating the departure time:**
\`\`\`js
GetTime => ${GetTime}
\`\`\`

**Looping over the result:**
\`\`\`js
for(let i = 0; i < timetable.length; i++) {
${LoopOverResults(results, selectedStation, "\t")}    
}
\`\`\`

.
`;};

let key=0;
const Timetable = ({
    results,
    origin,
    selectedStation,
}) => {

    const type = () => GetType(selectedStation?.type, results.agencies);

    return (
        selectedStation?.timetable?.length > 0 
        ?   (
                <ExampleCode showCodeTo="left" markdown={Markdown(origin, results, selectedStation)} headerText="Timetable" className="stations-timetable-container">
                    <div className="stations-timetable-item" key={`stations-timetable-item${key++}`}>
                        <span className="left">
                            icon:<small>{selectedStation.type}</small>
                            <br/>
                            {selectedStation.location.platformLine}
                            <br/>
                            {selectedStation.location.name}
                        </span>
                        <span className="right">
                        </span>
                    </div>
                    { selectedStation.timetable.map(RenderItems.bind(null, null, null, results, selectedStation)) }
                </ExampleCode>
            )
        : null
    );
};

export default Timetable;