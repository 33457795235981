import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

// stations
const DrawStation = (mapState, station) => {

    if(!station?.location?.coordinates?.latitude) { 
        console.log(`skipping station: ${JSON.stringify(station)}`);
        return null; 
    }

    let color;
    switch(station.type) {
        case "BUS": color = "orange"; break;
        case "TRAM": color = "yellow"; break;
        case "BICYCLE_RENT": color = "green"; break;
        case "PURPLE_POINT": color = "purple"; break;
        default: color = "blue"; break;
    }
    let latitude = station?.location?.coordinates?.latitude;
    let longitude = station?.location?.coordinates?.longitude;
    let marker = mapState.newMarker(color, longitude, latitude, null, false);
    marker.marker.setPopup(new mapboxgl.Popup().setHTML(`${station?.location?.name}`))
    return marker;
};

const ClearStations = (mapState, stations) => {
    stations.forEach((station) => { station.marker.remove(); });
};

// constructor
const StationsOnMap = ({
    mapState,
    results,
    selectedStation
}) => {

    let [state, setState] = React.useState({
        results: results,
        selectedStation: selectedStation
    });

    const ClearStateStations = () => {
        let stations = state.stations;
        if(stations != null) {
            ClearStations(mapState, stations);
        }
    };

    useEffect(() => {
        ClearStateStations();
        if(selectedStation != null) {
            let stations = [selectedStation]
                .map((station) => DrawStation(mapState, station))
                .filter((station) => station != null)
            ;
            setState({
                results: results,
                selectedStation,
    
                stations
            });
        }
    }, [selectedStation]);

    useEffect(() => {

        ClearStateStations();
        let stations;
        if(results?.filtered?.[0]?.stations?.length > 0) {
            stations = results.filtered[0].stations
                .map((station) => DrawStation(mapState, station))
                .filter((station) => station != null)
            ;
        }  

        setState({
            results: results,
            selectedStation,

            stations
        });

    }, [results]);
    return (
        state.results?.length > 0
        ?   (
                <div key="0"></div>
            )
        : null
    );
};

export default StationsOnMap;