import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { SettingsQuery } from "./SettingsQuery.js";

const GetFilterType = (filterType) => {
    let result;
    switch(filterType.type) {
        case "ALL": result = "All"; break;
        case "BICYCLE_RENT": result = "Bike (rental)"; break;
        case "BUS": result = "Bus"; break;
        case "WALK": result = "Foot"; break;
        case "CAR": result = "Car"; break;
        case "TRAM": result = "Tram"; break;
        case "SUBWAY": result = "Subway"; break;
        default:
            result = filterType.mode;
            break;
    }
    if(filterType && filterType.agency && filterType.agency.name) {
        result = filterType.agency.name;
    }
    return result;
};

const LoopOverResults = (results, tab) => {
    let lines = [];
    if(results?.length > 0) {
        results.forEach((filterType, index) => {

            lines.push(`${tab}...`);
            lines.push(`${tab}/*\n${tab}${JSON.stringify(filterType, null, 4).replace(/\n/g, "\n" + tab)}*/`);
            lines.push(`${tab}let filterType = filterTypes[${index}];`);
            
            lines.push(`${tab}let icon = filterType.type; // ${filterType.type}`);
            lines.push(`${tab}let text = GetFilterType(filterType); // By ${GetFilterType(filterType)}`);
        });
    }
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, results, filterType, index) => {

    return (
    <div key={JSON.stringify(filterType)} className="stations-filter-types-item" onClick={onSelect.bind(null, filterType, index)}>
        {<span className="middle">icon:<small>{filterType.type}</small> </span>}
        <span className="middle">
            {GetFilterType(filterType)}
        </span>
        <span className="middle">
            {
                filterType.include === true
                ? <small>SELECTED</small>
                : <small>.</small>
            }
        </span>
    </div>
)};

const Markdown = (
    results, 
    { 
        radius_mode,
        radius: {
            m, km, ft, mi
        },
        filter,
        general 
    }
    ) => { 

    if(!results) { return; }

    let _results = JSON.parse(JSON.stringify(results));
    delete _results.radius_mode;

    if(radius_mode === "metric") {
        delete _results.radius.ft;
        delete _results.radius.mi;
    } else {
        delete _results.radius.m;
        delete _results.radius.km;
    }

    /*
    ${(coordinates ? 
            `coordinates: {
                latitude: ${coordinates.latitude}
                longitude: ${coordinates.longitude}
            }`
        : "")}
        ${(coordinates && stationFilter?.type ? ',' : '')}
        ${(stationFilter?.type ? 
            `stationFilter: {
                type: "${stationFilter.type}"
            }`
        : "")}
    */

    return `
# Station Query Limits

The data on the left was retrieved using the following GraphQL query:
\`\`\`graphql
{
    settings
    {
        defaultStationQueryLimits
        {
            radius { ${radius_mode === "metric" ? "m, km" : "ft, mi" } }
            filter
            general
        }
    }
}
\`\`\`

The query above produced this result:
\`\`\`json
${JSON.stringify(_results, null, 4)}
\`\`\`

When querying the stations:
\`\`\`graphql
{
    easyStations(
        ...
        queryLimits: {
            radius: {
                ${radius_mode === "metric" ? `m: ${m},` : ``}${radius_mode === "imperial" ? `ft: ${ft},` : ``}
                ${radius_mode === "metric" ? `km: ${km},` : ``}${radius_mode === "imperial" ? `mi: ${mi},` : ``}
            },
            filter: ${filter},
            general: ${general}
        }
        ...
    ) {
        ...
    }
}
\`\`\`

---

.
`;};

const QueryLimits = ({
    onChange,
    inputRadius
}) => {

    let [state, setState] = React.useState({
        radius_mode: "metric",
        radius: {
            m: 0,
            km: 0,
            ft: 0,
            mi: 0
        },
        filter: 1,
        general: 1,
        radius_m: 0
    });

    let [settings, setSettings] = React.useState(null);

    // initialize
    useEffect(async () => {
        let _settings = await SettingsQuery();
        let result = _settings?.data?.settings?.defaultStationQueryLimits;
        if(result) {
            result.radius_mode = state.radius_mode;
            result.radius_m = result.radius.m + (result.radius.km * 1000);
            setSettings(JSON.parse(JSON.stringify(result)));
            setState(result);
            if(onChange) {
                onChange(result);
            }
        }
    }, []);

    // on input radius changed
    useEffect(async () => {

        let radius_m = inputRadius;
        let km = Math.floor(radius_m / 1000);
        let m = radius_m - (km * 1000);
        let ft = radius_m * 3.28084;
        let mi = Math.floor(ft / 5280);
        ft = ft - (mi * 5280);

        let filter = state.filter;
        let general = state.general;

        let radius = {
            m,
            km,
            ft,
            mi
        };
        let result = { 
            radius, 
            filter, 
            general,
            radius_mode: state.radius_mode,
            radius_m
        };
        setState(result);
        if(onChange) {
            onChange(result);
        }

    }, [inputRadius]);

    const _onChange = (type, e) => {

        let m = state.radius.m;
        let km = state.radius.km;
        let ft = state.radius.ft;
        let mi = state.radius.mi;

        let filter = state.filter;
        let general = state.general;

        let value;
        try {
            value = Number.parseInt(e.target.value);
        } catch(err) {
            console.error(err);
            return;
        }

        switch(type) {
            case "radius-metric-m": m = value; break;
            case "radius-metric-km": km = value; break;
            case "radius-imperial-ft": ft = value; break;
            case "radius-imperial-mi": mi = value; break;

            case "limit-filter": filter = value; break;
            case "limit-general": general = value; break;
        }

        let radius_m = 0;
        switch(state.radius_mode) {
            case "metric": radius_m = m + (km * 1000); break;
            case "imperial": radius_m = (ft * 3.28084) + (mi * 1609.34); break;
        }

        if(filter > general) { filter = general; }

        let radius = {
            m,
            km,
            ft,
            mi
        };
        let result = { 
            radius, 
            filter, 
            general,
            radius_mode: state.radius_mode,
            radius_m
        };
        setState(result);
        if(onChange) {
            onChange(result);
        }
    };

    const _onSelectMode = (mode) => {

        let m = state.radius.m;
        let km = state.radius.km;
        let ft = state.radius.ft;
        let mi = state.radius.mi;

        // then translate the data from metric to imperial
        if(mode === "imperial") {
            let _m = m + (km * 1000);
            let _ft = _m * 3.28084;

            mi = Math.floor(_ft / 5280);
            ft = _ft - (mi * 5280);
        } else {
            let _ft = ft + (mi * 5280);
            let _m = _ft / 3.28084;

            km = Math.floor(_m / 1000);
            m = _m - (km * 1000);
        }

        let radius_m = m + (km * 1000);

        let result = {
            ...state,
            radius_m,
            radius_mode: mode,
            radius: {
                m,
                km,
                ft,
                mi
            }
        };
        setState(result);
        if(onChange) {
            onChange(result);
        }
    };

    return (
        state 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(settings, state)} headerText="Query Limits" className="stations-query-limits-container">
                    <div className="stations-query-limits-other-container">
                        <label>Filter Limit</label>
                        <input onChange={_onChange.bind(null, 'limit-filter')} type="number" value={state.filter}/>
                        <input onChange={_onChange.bind(null, 'limit-filter')} type="range" min="0" max={state.general >= 0 ? state.general : 0} value={state.filter} className="slider"/>
                        <label>General Limit</label>
                        <input onChange={_onChange.bind(null, 'limit-general')} type="number" value={state.general}/>
                        <input onChange={_onChange.bind(null, 'limit-general')} type="range" min={state.filter >= 0 ? state.filter : 0} max="100" value={state.general} className="slider"/>
                    </div>
                    <div className="stations-query-limits-radius-container">
                        <span>Radius</span>
                        <div className="stations-query-limits-radius-container-type">
                            <input
                                type="radio"
                                name="radius-mode"
                                onChange={_onSelectMode.bind(null, "metric")}
                                checked={state.radius_mode === "metric"}>
                            </input> Metric
                            <input
                                type="radio"
                                name="radius-mode"
                                onChange={_onSelectMode.bind(null, "imperial")}
                                checked={state.radius_mode === "imperial"}>
                            </input> Imperial
                        </div>
                        { state.radius_mode === "metric" ?
                            <div className="stations-query-limits-radius-container-metric" key="stations-query-limits-radius-container-metric">
                                <label>Meters</label>
                                <input onChange={_onChange.bind(null, 'radius-metric-m')} type="number" min={0} max={15000} value={state.radius.m}/>
                                <input onChange={_onChange.bind(null, 'radius-metric-m')} type="range" min={0} max={15000} value={state.radius.m} className="slider"/>
                                <label>Kilometers</label>
                                <input onChange={_onChange.bind(null, 'radius-metric-km')} type="number" value={state.radius.km}/>
                                <input onChange={_onChange.bind(null, 'radius-metric-km')} type="range" min={0} max={100} value={state.radius.km} className="slider"/>
                            </div>
                            : null
                        }
                        { state.radius_mode === "imperial" ?
                            <div className="stations-query-limits-radius-container-imperial" key="stations-query-limits-radius-container-imperial">
                                <label>Feet</label>
                                <input onChange={_onChange.bind(null, 'radius-imperial-ft')} type="number" value={state.radius.ft}/>
                                <input onChange={_onChange.bind(null, 'radius-imperial-ft')} type="range" min={0} max={15000} value={state.radius.ft} className="slider"/>
                                <label>Miles</label>
                                <input onChange={_onChange.bind(null, 'radius-imperial-mi')} type="number" value={state.radius.mi}/>
                                <input onChange={_onChange.bind(null, 'radius-imperial-mi')} type="range" min={0} max={100} value={state.radius.mi} className="slider"/>
                            </div>
                            : null
                        }
                    </div>
                </ExampleCode>
            )
        : null
    );
};

export default QueryLimits;