import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { SettingsQuery } from "./SettingsQuery.js";

const GetFilterType = (filterType) => {
    let result;
    switch(filterType.type) {
        case "ALL": result = "All"; break;
        case "BUS": result = "Bus"; break;
        case "TRAM": result = "Tram"; break;
        default:
            result = filterType.mode;
            break;
    }
    if(filterType && filterType.agency && filterType.agency.name) {
        result = filterType.agency.name;
    }
    return result;
};

const LoopOverResults = (results, tab) => {
    let lines = [];
    if(results?.length > 0) {
        results.forEach((filterType, index) => {

            lines.push(`${tab}...`);
            lines.push(`${tab}/*\n${tab}${JSON.stringify(filterType, null, 4).replace(/\n/g, "\n" + tab)}*/`);
            lines.push(`${tab}let filterType = filterTypes[${index}];`);
            
            lines.push(`${tab}let icon = filterType.type; // ${filterType.type}`);
            lines.push(`${tab}let text = GetFilterType(filterType); // By ${GetFilterType(filterType)}`);
        });
    }
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, results, filterType, index) => {

    return (
    <div key={JSON.stringify(filterType)} className="lines-filter-types-item" onClick={onSelect.bind(null, filterType, index)}>
        {<span className="middle">icon:<small>{filterType.type}</small> </span>}
        <span className="middle">
            {GetFilterType(filterType)}
        </span>
        <span className="middle">
            {
                filterType.include === true
                ? <small>SELECTED</small>
                : <small>.</small>
            }
        </span>
    </div>
)};

const Markdown = (results) => { 

    let relevantResults = results.map((item) => {
        return {
            type: item.type,
            agency: item.agency
        };
    });

    return `
# Line Filters

The data on the left was retrieved using the following GraphQL query:

\`\`\`graphql
{
    settings
    {
        availableLineFilters
        {
            type
            agency { name }
        }
    }
}
\`\`\`

The query above produced this result:
\`\`\`json
${JSON.stringify(relevantResults, null, 4)}
\`\`\`

**Get filter:**
\`\`\`js
GetFilterType => ${GetFilterType}
\`\`\`


**Looping over the result:**
\`\`\`js
for(let i = 0; i < filterTypes.length; i++) {
${LoopOverResults(relevantResults, "\t")}    
}
\`\`\`

---

.
`;};

const FilterTypes = ({
    onChange,
}) => {

    let [currentResults, setCurrentResults] = React.useState([]);

    // initialize
    useEffect(async () => {
        let settings = await SettingsQuery();
        let result = settings?.data?.settings?.availableLineFilters;
        result.forEach((r, i) => { r.include = i === 0; });
        if(result) {
            setCurrentResults(result);
            onChange(result);
        }
    }, []);

    const onItemSelect = (item, index) => {
        if(item.include === true) { return; }
        item.include = true;
        let copy = JSON.parse(JSON.stringify(currentResults));
        copy.forEach((_item, _index) => {
            _item.include = _index === index;
        });
        copy[index] = item;
        setCurrentResults(copy);
        console.log(`onItemSelect: ${JSON.stringify(item)}`)
        onChange(item);
    };

    return (
        currentResults?.length > 0 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(currentResults)} headerText="Line Filters (types)" className="lines-filter-types-container">
                    { currentResults.map(RenderItems.bind(null, onItemSelect, currentResults)) }
                </ExampleCode>
            )
        : null
    );
};

export default FilterTypes;