import React, { useEffect } from 'react';
import SuggestedTransports from "./SuggestedTransports.js";
import SuggestedRoutes from "./SuggestedRoutes.js";
import TransporTypes from "./TransportTypes.js";
import Legs from "./Legs.js";
import Path from "./Path.js";
import Steps from "./Steps.js";

const Directions = ({
    mapState,
    results,
    origin,
    destination,
    directionsResult,
    suggestedTransports,
    suggestedRoutes,
    onSelectTransport,
    onSelectRoute,
    onSelectLeg,
    onTransportTypes
}) => {

    let [transportTypes, setTransportTypes] = React.useState([]);
    const _onTransportTypes = (transportTypes) => {
        setTransportTypes(transportTypes);
        onTransportTypes(transportTypes);
    };

    let [routeLegs, setRouteLegs] = React.useState([]);
    let [routeDirections, setRouteDirections] = React.useState([]);
    let [selectedRoute, setSelectedRoute] = React.useState(null);
    const _onSelectRoute = (route) => {
        if(onSelectRoute) {
            onSelectRoute(route);
        }
        setRouteLegs(route.legs);

        // instead of using these directions
        setRouteDirections(route.directions);
        // use the steps included in each leg
        //setRouteDirections(route.legs.map((leg) => {
        //    return { steps: leg.steps, mode: leg.mode, start: {
        //        name: leg?.from?.name
        //    } };
        //}));

        setSelectedRoute(route);
    };

    let [selectedLeg, setSelectedLeg] = React.useState(null);
    const _onSelectedLeg = (leg) => {
        if(onSelectLeg) {
            onSelectLeg(leg);
        }
        setSelectedLeg(leg);
    };

    const _onSelectTransport = (suggestedTransport) => {
        if(onSelectTransport) {
            onSelectTransport(suggestedTransport);
        }
        _onSelectRoute(results.suggestedRoutes[suggestedTransport.suggestedRoute.reference]);
    };

    let [drawRoute, setDrawRoute] = React.useState(true);
    let [selectedStep, setSelectedStep] = React.useState(null);
    const _onDrawStep = () => {
        setDrawRoute(false);
        setSelectedStep(null);
    };

    const _onDrawRoute = () => {
        setDrawRoute(true);
        setSelectedStep(null);
    };

    const _onSelectStep = (step) => {
        setDrawRoute(false);
        // we also need to let the "path"
        setSelectedStep(step);
    };

    let [selectedDirectionsIndex, setSelectedDirectionsIndex] = React.useState(0);
    const _onSelectDirectionsIndex = (index) => {
        setSelectedDirectionsIndex(index);
        setSelectedStep(null);
    };

    return (
        <div>
            <Path mapState={mapState} results={results} selectedRoute={selectedRoute} selectedLeg={selectedLeg} selectedStep={selectedStep} drawRoute={drawRoute} selectedDirectionsIndex={selectedDirectionsIndex}/> 
            <TransporTypes onChange={_onTransportTypes}/>
            <SuggestedTransports transportTypes={transportTypes} origin={origin} destination={destination} suggestedTransports={suggestedTransports} results={results} onSelect={_onSelectTransport}/>
            <SuggestedRoutes transportTypes={transportTypes} origin={origin} destination={destination} suggestedRoutes={suggestedRoutes} results={results} onSelect={_onSelectRoute}/>
            <Legs legs={routeLegs} results={results} onSelect={_onSelectedLeg}/>
            <Steps directions={routeDirections} onSelectStep={_onSelectStep} drawRoute={drawRoute} onDrawStep={_onDrawStep} onDrawRoute={_onDrawRoute} results={results} onSelectDirectionsIndex={_onSelectDirectionsIndex}/>
        </div>
    );
};

export default Directions;