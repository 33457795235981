import React, { useEffect } from 'react';
import Autosuggest from "react-autosuggest";

const debounce = (func, delay, immediate) => {
    let timerId;
    return (...args) => {
        const boundFunc = func.bind(this, ...args);
        clearTimeout(timerId);
        if (immediate && !timerId) {
            boundFunc();
        }
        const calleeFunc = immediate ? () => { timerId = null } : boundFunc;
        timerId = setTimeout(calleeFunc, delay);
    }
}

const AutoComplete = ({
    setValueHook,
    items,
    onChangeEvent,
    onSelectEvent,
    onClearRequest,
    placeholder,
    onVoid
}) => {

    const [value, setValue] = React.useState("");
    if(setValueHook) {
        setValueHook.setCall = setValue;
    }

    const sendEvent = () => { onChangeEvent(value); }
    const _sendEvent = debounce(() => sendEvent(), 300);
    const onChange = (event, {newValue}) => { setValue(newValue); };
    const onSuggestionsFetchRequested = (e) => { _sendEvent(); };
    const onSuggestionsClearRequested = (e) => { onClearRequest(); };

    const _onVoid = () => {
        setValue("");
        onVoid();
    };

    const getSuggestionValue = (e) => {
        onSelectEvent(e);
        return e.place_name;
    };

    const renderSuggestion = (item) => (
        <div>
            {item.place_name}
        </div>
    );

    const inputProps = {
        placeholder,
        value,
        onChange
    };

    return (
        <div className="autocomplete-container">
            <Autosuggest
                suggestions={items}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
            <button type="button" onClick={_onVoid}>X</button>
        </div>
    );
};

export default AutoComplete;