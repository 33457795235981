// on route select, draw the route's polyline
// on leg select, draw the leg's polyline

import React, { useEffect } from 'react';
import polyline from "@mapbox/polyline";
import mapboxgl from 'mapbox-gl';

// draw the new ones
const reveserLineItem = (item) => [item[1], item[0]];
const reverseLine = (line) => line.map(reveserLineItem);
const getColor = (leg) => {
    switch(leg.mode) { 
        case "WALK":
            return "red";
        case "BUS": return "orange";
        case "TRAM": return "yellow";
        default: return "lightblue";
    }
};

// routes
const DrawLeg = (mapState, leg) => {
    if(leg == null) { return; }
    let color = getColor(leg);
    let line = polyline.decode(leg.polyline);
    let sourceId = mapState.addSource({
        type: "geojson",
        data: {
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: reverseLine(line)
            }
        }
    });
    let layer = mapState.addLayer(sourceId, {
        type: "line",
        layout: {
            "line-join": "round",
            "line-cap": "round"
        },
        paint: {
            "line-color": color,
            "line-width": 3
        }
    });
    return {
        color,
        line,
        sourceId,
        layerId: layer.id
    }
};

const ClearPaths = (mapState, paths) => {
    paths.forEach((path) => {
        mapState.removeLayer(path.layerId);
        mapState.removeSource(path.sourceId);
    });
};

// stops
const DrawStop = (mapState, stop) => {
    let color = "green";
    let latitude = stop?.location?.coordinates?.latitude;
    let longitude = stop?.location?.coordinates?.longitude;
    let marker = mapState.newMarker(color, longitude, latitude, null, false);
    marker.marker.setPopup(new mapboxgl.Popup().setHTML(`${stop?.location?.name}`))
    return marker;
};

const ClearStops = (mapState, stops) => {
    stops.forEach((stop) => { stop.marker.remove(); });
};

// directions
const DrawDirections = (mapState, selectedRoute, directionsIndex) => {

    if(directionsIndex >= selectedRoute.directions.length) { return null; }

    let direction = selectedRoute.directions[directionsIndex];
    let steps = direction.steps.map((step) => {
        let color = "blue";
        let latitude = step?.location?.coordinates?.latitude;
        let longitude = step?.location?.coordinates?.longitude;

        if(latitude == null || longitude == null) {
            return null;
        }

        let marker = mapState.newMarker(color, longitude, latitude, null, false);
        marker.marker.setPopup(new mapboxgl.Popup().setHTML(`${step.direction.relative} ${step?.location?.name}`))
        return marker;
    }).filter((step) => step != null);

    return steps;
};

// single step
const DrawSingleStep = (mapState, step) => {

    let color = "blue";
    let latitude = step?.location?.coordinates?.latitude;
    let longitude = step?.location?.coordinates?.longitude;

    if(latitude == null || longitude == null) {
        return null;
    }

    let marker = mapState.newMarker(color, longitude, latitude, null, false);
    marker.marker.setPopup(new mapboxgl.Popup().setHTML(`${step.direction.relative} ${step?.location?.name}`));
    
    return [marker];
};

// constructor
const Path = ({
    mapState,
    results,
    selectedRoute,
    selectedLeg,
    selectedStep,
    selectedDirectionsIndex,
    drawRoute
}) => {

    let [state, setState] = React.useState({
        drawDirections: !drawRoute,
        drawRoute: drawRoute,
        results: results,
        selectedRoute: selectedRoute,
        selectedLeg: selectedLeg,
        selectedDirectionIndex: selectedDirectionsIndex
    });

    useEffect(() => {

        let routePaths = state.routePaths;
        let stops = state.stops;

        if(routePaths != null) {
            ClearPaths(mapState, routePaths);
            routePaths = null;
        }

        if(stops != null) {
            ClearStops(mapState, stops);
            stops = null;
        }

        if(selectedStep != null) {
            routePaths = selectedRoute.legs.map((leg) => DrawLeg(mapState, leg));
            stops = DrawSingleStep(mapState, selectedStep);
        } else {
            if(selectedRoute != null && drawRoute === true) {
                routePaths = selectedRoute.legs.map((leg) => DrawLeg(mapState, leg));
                stops = selectedRoute.legs
                    .filter((leg) => leg.stops != null)
                    .flatMap((leg) => leg.stops.map((stop) => DrawStop(mapState, stop)));
            }

            if(selectedRoute != null && drawRoute === false) {
                routePaths = selectedRoute.legs.map((leg) => DrawLeg(mapState, leg));
                stops = DrawDirections(mapState, selectedRoute, selectedDirectionsIndex);
            }
        }

        setState({
            drawDirections: state.drawDirections,
            drawRoute: state.drawRoute,

            results: results,
            selectedRoute: selectedRoute,
            selectedLeg: selectedLeg,
            selectedStep: selectedStep,
            selectedDirectionIndex: selectedDirectionsIndex,

            routePaths,
            stops
        });

    }, [results, selectedRoute, selectedLeg, drawRoute, selectedDirectionsIndex, selectedStep]);

    return (
        state.results?.length > 0
        ?   (
                <div key="0"></div>
            )
        : null
    );
};

export default Path;