import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";

const GetCostString = (fare, currencies) => {

    if(!fare?.currency?.reference || !currencies) { return ""; }

    let currency = currencies[fare.currency.reference];
    let digits = currency.fractionDigits;
    let div = Math.pow(10, digits);

    return `${currency.code} ${Number(fare.value / div).toFixed(digits)}`;
}

const LoopOverResults = (directions, results, tab, selectedIndex) => {
    let lines = [];
    let steps = directions[selectedIndex].steps;
    steps.forEach((_step, index) => {
        let step = {
            direction: _step?.direction,
            location: _step?.location,
            stayOn: _step?.stayOn,
            distance: _step?.distance
        };
        lines.push(`${tab}...`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(step, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let step = steps[${index}];`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(ParseStep(step), null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let parsedStep = ParseStep(step);`);
        //lines.push(`${tab}let travel_mode = GetTravelMode(leg.mode); // ${GetTravelMode(leg.mode)}`);
        //lines.push(`${tab}let destination = leg.to.name; // ${leg.to.name}`);
        //lines.push(`${tab}let duration = \`\${leg.duration.minutes} min\`; // ${leg.duration.minutes} min`);
        //lines.push(`${tab}let fare = GetFare(leg.fare, result.currencies); // ${GetCostString(leg.fare, results.currencies)}`);
        //lines.push(`${tab}let text = \`\${travel_mode} to \${destination}\`; // ${GetTravelMode(leg.mode)} to ${leg.to.name}`);
        try {
            //lines.push(`${tab}let start = new Date(route.start); // start(${route.start}) -> ${new Date(route.start)}`);
            //lines.push(`${tab}let end = new Date(route.end); // end(${route.end}) -> ${new Date(route.end)}`);
        } catch(err) {
            console.error(err);
        }
    });
    return lines.join("\n");
};

const GetTravelMode = (mode) => {
    let modeText;
    switch(mode) {
        case "BICYCLE_RENT": 
        case "BICYCLE":
            modeText = "Cycle to"; break;
        case "BUS": modeText = "To"; break;
        case "WALK": modeText = "Walk to"; break;
        case "CAR": modeText = "Drive to"; break;
        case "TRAM": modeText = "To"; break;
        default:
            modeText = "Traverse to";
            break;
    }
    return modeText;
};

let __key=0;
const Render = (directions, directionsResult, selectedIndex, onSelect, drawRoute, onDrawSteps, onDrawRoute, onSelectStep) => {

    let modeText = "";
    let text;
    switch(directions[selectedIndex].mode) {
        default:
            modeText = GetTravelMode(directions[selectedIndex].mode);
            text = `${modeText} ${directions[selectedIndex].to.name}`;
            break;
    }

    return (
        <div key={JSON.stringify(directions)} className="directions-steps">
            <div className="directions-steps-select">

                {
                    drawRoute === true
                    ? <button onClick={onDrawSteps}>Draw Steps</button>
                    : <button onClick={onDrawRoute}>Draw Route</button>
                }


                {directions.map((item, index) => 
                    <input 
                        type="radio" 
                        name="directions-steps-results" 
                        value={`${index}`} 
                        onChange={onSelect.bind(null, item, index)} 
                        checked={selectedIndex === index}>

                    </input>
                )}
            </div>
            <div className="directions-steps-item">
                <span className="left"><nobr><small>icon:{directions[selectedIndex].mode}</small></nobr></span>
                <span className="right"></span>
                <span className="left">{text}</span>
                <span className="right">{directions[selectedIndex].duration ? `${directions[selectedIndex]?.duration?.minutes} min` : ""}</span>
                <span className="left"><nobr><small>{directions[selectedIndex].headsign} {directions[selectedIndex].from.platformLine}</small></nobr></span>
            </div>
            { directions[selectedIndex].steps.map(RenderSteps.bind(null, directionsResult, onSelectStep)) }
            {/* stops.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) */}
        </div>
    );
};

const Capitalize = (str) => {
    if(!str) { return ""; }
    return str
        .split(" ")
        .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1, word.length).toLowerCase()}`)
    ;
}

const ParseStep = (step) => {
    let direction = step.direction;
    let icon;
    let text;
    let to;
    let removeLength;
    let smallText;

    if(direction !== null) {
        icon = "icon:"+direction.relative;
    }
    if(smallText !== null && step.location !== null) {
        smallText = step.location.name;
    }

    switch(direction?.relative) {

        case "CIRCLE_CLOCKWISE":
        case "CIRCLE_COUNTERCLOCKWISE":
            removeLength = "CIRCLE_".length;
            to = Capitalize(direction.relative.substring(removeLength, direction.relative.length));
            text = `Circle ${to}`;
            break;

        case "CONTINUE":
            to = "forward";
            text = `Continue on`;
            break;

        case "DEPART":
            icon = "icon:"+direction.absolute;
            to = Capitalize(direction.absolute);
            text = `Head ${to}`;
            break;

        case "DROP_ON_STATION":
            text = `Drop On Station`;
            break;
        case "DROP_OFF_STATION":
            text = `Drop Off Station`;
            break;

        case "ARRIVE_AT_DESTINATION":
            text = `Arrive At Destination`;
            break;

        case "GO_TO_PLATFORM":
            text = `Go To Platform`;
            break;

        case "ELEVATOR":
            break;

        case "HARD_LEFT":
        case "HARD_RIGHT":
            removeLength = "HARD_".length;
            to = Capitalize(direction.relative.substring(removeLength, direction.relative.length));
            text = `Turn Hard ${to}`;
            break;

        case "SLIGHTLY_LEFT":
        case "SLIGHTLY_RIGHT":
            removeLength = "SLIGHTLY_".length;
            to = Capitalize(direction.relative.substring(removeLength, direction.relative.length));
            text = `Turn Slightly ${to}`;
            break;

        case "UTURN_LEFT":
        case "UTURN_RIGHT":
            removeLength = "UTURN_".length;
            to = Capitalize(direction.relative.substring(removeLength, direction.relative.length));
            text = `U-Turn ${to}`;
            break;

        case "RIGHT":
        case "LEFT":
            to = Capitalize(direction.relative);
            text = `Turn ${to}`;
            break;

        case "GO_TO_STREET":
            to = Capitalize(direction.absolute);
            text = `Go to street`;
            smallText = `Exit ${to}`;
            break;

        default:
            break;
    }
    return {
        text: text,
        icon: icon,
        durationMinutes: step?.duration?.minutes,
        smallText: smallText
    };
}

const RenderSteps = (directionsResult, onSelectStep, step,) => {

    let parsedStep = ParseStep(step);

    const _onSelectStep = () => {
        if(onSelectStep) {
            onSelectStep(step);
        }
    };

    step._key = __key++;
    return (
    <div key={JSON.stringify(step)} className="directions-steps-item" onClick={_onSelectStep}>
        <span className="left"><nobr><small>{parsedStep.arrow}</small> {parsedStep.text}</nobr></span>
        <span className="right">{parsedStep.durationMinutes} min</span>
        <span className="left"><small>{parsedStep.smallText}</small></span>
    </div>
)};

const Markdown = (results, directions, selectedIndex) => { 
    if(selectedIndex > directions.length - 1) { return null; }
    /*
    let relevantResults = [];
    legs.forEach((_leg) => {
        let leg = {
            mode: _leg.mode,
            from: _leg.from,
            to: _leg.to,
            duration: _leg.duration,
            fare: _leg.fare
        };
        relevantResults.push(leg);
    });
    */
    let relevantJson = JSON.stringify(directions, null, 4);
    return `
# Directions

The data at the top is a sub selection produced by the following GraphQL query:
> Please note:
> that only relevant data is displayed; an ellipsis is used indicate missing fields.

\`\`\`graphql
{
	easyDirections(
        ...
    ) {
        agencies { ... }
        currencies { ... }
        suggestedTransports { ... }
        suggestedRoutes { 
            ...
            directions {
                mode
                duration { minutes }
                from {
                    name
                    platformLine
                    coordinates { latitude longitude }
                }
                to {
                    name
                    platformLine
                    coordinates { latitude longitude }
                }
                steps {
                    direction { relative absolute },
                    location {
                        coordinates { latitude longitude },
                        name
                        area
                        bogusName
                    }
                    stayOn
                    distance
                }
            }
        }
    }
}
\`\`\`

The query above, and sub selection, produced this result:
\`\`\`json
${relevantJson}
\`\`\`

**Object references:**

Some object types are referencable; they are included as a top level array where the reference field is a numeric index.
The following object types are referenced here:
 * currency

> Using this method is opt-in and you may choose to include the fields of the referenced objects directly.

**Capatilize**
\`\`\`js
Capitalize => ${Capitalize}
\`\`\`

**Calculating the travel modes**
\`\`\`js
GetTravelMode => ${GetTravelMode}
\`\`\`

**Parsing the steps**
\`\`\`js
ParseStep => ${ParseStep}
\`\`\`

**Looping over the result:**
\`\`\`js
/*
${JSON.stringify(directions[selectedIndex], null, 4)}
*/
// (the index is is the selected radio button index)
let directionsItem = directions[${selectedIndex}]; // see JSON above
let steps = directionsItem.steps; // directionsItem.steps.length is ${directions?.[selectedIndex]?.steps?.length}
/*
${JSON.stringify({
    from: directions[selectedIndex].from,
    to: directions[selectedIndex].to
}, null, 4)}
*/
let to = directionsItem.to; // see JSON above
let startIcon = \`icon:\${directionsItem.mode}\`;      // icon:${directions[selectedIndex].mode}
let startMode = GetTravelMode(directionsItem.mode); // ${GetTravelMode(directions[selectedIndex].mode)} 
let startText = \`\${startMode} to \${to.name}\`; // ${GetTravelMode(directions[selectedIndex].mode)} to ${directions?.[selectedIndex]?.to?.name}
let startDuration = \`\${directions[selectedIndex].?duration?.minutes} min\`; // ${directions[selectedIndex]?.duration?.minutes} min
let startSmallText = \`\${directions[selectedIndex].from.platformLine}\`; // ${directions[selectedIndex]?.from?.platformLine}
for(let i = 0; i < steps.length; i++) {
${LoopOverResults(directions, results, "\t", selectedIndex)}
}
\`\`\`

.
`;};

const Steps = ({
    results,
    directions,
    onSelectDirectionsIndex,
    onDrawStep,
    onDrawRoute,
    onSelectStep,
    drawRoute
}) => {

    let [state, setState] = React.useState({
        directions: directions,
        index: 0
    });

    const _onSelect = (item, index) => {
        setState({
            directions: state.directions,
            index: index
        });

        if(onSelectDirectionsIndex) {
            onSelectDirectionsIndex(index);
        }
    };

    const _onSelectStep = (step) => {
        if(onSelectStep) {
            onSelectStep(step);
        }
    };

    const _onDrawSteps = () => {
        if(onDrawStep) {
            onDrawStep();
        }
    };

    const _onDrawRoute = () => {
        if(onDrawRoute) {
            onDrawRoute();
        }
    }

    useEffect(() => {
        //if(selectedIndex > directions.length - 1) {
        //    setSelectedIndex(0);
        //}
        //setCurrentResults(directions);
        setState({
            directions: directions,
            index: 0
        });
    }, [directions, results]);

    return (
        state?.directions?.length > 0 && state?.index <= state?.directions?.length
        ?   (
                <ExampleCode showCodeTo="left" markdown={Markdown(results, directions, state.index)} headerText="Directions" className="directions-steps-container">
                    {Render(state.directions, results, state.index, _onSelect, drawRoute, _onDrawSteps, _onDrawRoute, _onSelectStep)}
                </ExampleCode>
            )
        : null
    );
};

export default Steps;