import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const PadLeft = (length, str) => {
    if(length - (''+str).length < 0) { return str; }
    let pad = new Array(length - (''+str).length);
    pad.fill('0', 0, pad.length);
    return `${pad.join('')}${str}`;
};

const GetTime = (time) => {
    if(time == null) { return ""; }
    return `${PadLeft(2,time.formatted.hours)}:${PadLeft(2,time.formatted.minutes)} ${time.formatted.isPM ? 'pm' : 'am'}`;
};

const LoopOverResults = (results, tab) => {
    try {
    let lines = [];
    results.filtered[0].stations[0].timetable.forEach((entry, index) => {
        let result = {
            departureTime: entry.departureTime,
            location: entry.location,
        };
        lines.push(`${tab}...`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(result, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let entry = timetable[${index}];`);
        
        lines.push(`${tab}let name = entry.location.name; // ${entry.location.name}`);
        lines.push(`${tab}let time = GetTime(entry.departureTime); // ${GetTime(entry.departureTime)}`);
    });
    return lines.join("\n");
    } catch(err) {
        console.error(err);
        return ``;
    }
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, results, timetable,) => {

    console.log(``)
    let name = timetable.location.name;
    let time = GetTime(timetable.departureTime);
    return (
    <div key={__key++} className="lines-timetable-item" onClick={onSelect.bind(null, timetable)}>
        <span className="left">
            <b>{name}</b>
        </span>
        <span className="right">
            {time}
        </span>
    </div>
)};

const Markdown = (originString, selectedStation, results) => { 

    //if(true) { return ""; }

    let origin = originString.split(", ");
    let relevantResults = {
        filtered: []
    };
    results.filtered.forEach((route) => {
        //let stations = route.stations.filter((_, index) => index < 5);
        let stations = route.stations.map((station) => {
            let timetable = station.timetable.filter((_, idx) => idx < 5);
            return {
                ...station,
                timetable
            };
        });
        //route.stations = stations;
        relevantResults.filtered.push({ ...route, stations });
    });
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Station

The data on the right was retrieved using the following GraphQL query:

\`\`\`graphql
{
    easyLines(
        coordinates: {
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        }
        station: "${selectedStation.iref}"
    ) {
        filtered {
            iref
            name
            location { address }
            distance { m, km }
            duration { formatted { minutes seconds } }
            type
            agency { name }
            polyline
            stations {
                iref
                reference
                location { address }
                distance { m, km }
                timetable {
                    departureTime {
                        formatted {
                            hours,
                            minutes,
                            seconds,
                            isPM
                        }
                    }
                    location {
                        name
                    }
                }
            }
        }
    }
}
\`\`\`

The query above produced this result:
> Please note: timetable entries have been limited to five (5) records
\`\`\`json
${relevantJson}
\`\`\`

**Padding:**
\`\`\`js
PadLeft => ${PadLeft}
\`\`\`

**Calculating the departure time:**
\`\`\`js
GetTime => ${GetTime}
\`\`\`

**Looping over the result:**
\`\`\`js

let line = filtered[0];
let line_polyline = line.polyline; // ${relevantResults.filtered[0].polyline}
let line_type = line.type; // ${relevantResults.filtered[0].type}

let station = line.stations[0];
let station_address = station.location.address; // ${relevantResults?.filtered[0]?.stations[0]?.location?.address}

for(let i = 0; i < filtered[0].stations[0].timetable.length; i++) {
${LoopOverResults(relevantResults, "\t")}    
}
\`\`\`

---

.
`;};

let _key=0;
const Station = ({
    results,
    origin,
    selectedStation,
    onSelect,
}) => {

    let [currentResults, setCurrentResults] = React.useState(results);

    useEffect(() => {
        setCurrentResults(results);
    }, [results]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        onSelect(item);
    };

    return (
        results?.filtered?.length > 0 
        ?   (
                <ExampleCode showCodeTo="left" markdown={Markdown(origin, selectedStation, results)} headerText="Station" className="lines-timetable-container">
                    <div className="lines-timetable-item" key={`lines-timetable-item${_key++}`}>
                        <span className="left">
                            icon:<small>{selectedStation?.type} {selectedStation?.name}</small>
                            <br/>
                            {selectedStation?.location?.address}
                        </span>
                        <span className="right"></span>
                    </div>
                    { results?.filtered?.[0]?.stations?.[0]?.timetable?.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default Station;