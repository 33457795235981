import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const PadLeft = (length, str) => {
    let pad = new Array(length - (''+str).length);
    pad.fill('0', 0, pad.length);
    return `${pad.join('')}${str}`;
};

const GetTime = (time) => {
    if(time == null) { return ""; }
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let is_pm = hours > 12;
    return `${PadLeft(2,hours)}:${PadLeft(2,minutes)} ${is_pm ? 'pm' : 'am'}`;
};

const GetDuration = (duration) => {

    let tokens = [];
    if(duration?.formatted != null) {
        if(duration.formatted.hours > 0) { tokens.push(`${duration.formatted.hours} hr`); }
        if(duration.formatted.minutes > 0) { tokens.push(`${duration.formatted.minutes} min`); }
        if(duration.formatted.seconds > 0) { tokens.push(`${duration.formatted.seconds} sec`); }
    }
    return tokens.join(" ");
};

const GetInterval = (interval) => {
    if(interval?.unknown === true) {
        return "Interval Unknown";
    }
    let tokens = [];
    if(interval?.formatted != null) {
        if(interval.formatted.hours > 0) { tokens.push(`${interval.formatted.hours} hr`); }
        if(interval.formatted.minutes > 0) { tokens.push(`${interval.formatted.minutes} min`); }
        if(interval.formatted.seconds > 0) { tokens.push(`${interval.formatted.seconds} sec`); }
    }
    return `Every ${tokens.join(" ")}`;
};

const GetTimeFromMillis = (millis) => {
    if(!millis) { return ""; }
    let time = new Date(millis);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let is_pm = hours > 12;
    return `${PadLeft(2,`${hours}`)}:${PadLeft(2,`${minutes}`)} ${is_pm ? 'pm' : 'am'}`;
};

const GetType = (type, agency, agencies) => {
    if(type == null) { return ""; }
    let result = null;
    let agencyReference = null;
    switch(type) {
        case "BICYCLE_RENT": 
        case "PURPLE_POINT":
            result = agency?.name; 
            agencyReference = agency?.reference;
            break;
        case "BICYCLE": result = "Bike"; break;
        case "BUS": result = "Bus"; break;
        case "WALK": result = "Foot"; break;
        case "CAR": result = "Car"; break;
        case "TRAM": result = "Tram"; break;
        default:
            result = type;
            break;
    }

    if(agencyReference != null) {
        result = agencies[agencyReference].name;
    }
    return result;
};

const LoopOverResults = (results, tab) => {
    let lines = [];
    
    results.stations.forEach((station, index) => {
        /*
        let transport = {
            mode: suggestedTransport.mode,
            agency: suggestedTransport.agency,
            duration: suggestedTransport.duration,
            fare: suggestedTransport.fare
        };
        */
        //lines.push(`${tab}...`);
        //lines.push(`${tab}/*\n${tab}${JSON.stringify(transport, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        //lines.push(`${tab}let transport = transports[${index}];`);
        
        //lines.push(`${tab}let icon = transport.mode; // ${suggestedTransport.mode}`);
        //lines.push(`${tab}let travel_mode = "By " + GetTravelMode(transport.mode, transport.agency, result.agencies); // By ${GetTravelMode(suggestedTransport.mode, suggestedTransport.agency, results.agencies)}`);
        //lines.push(`${tab}let duration = GetDuration(transport.duration) + " min"; // ${GetDuration(suggestedTransport.duration)} min`);
        //lines.push(`${tab}let fare = GetFare(transport.fare, result.currencies); // ${GetCostString(suggestedTransport.fare, results.currencies)}`);
        
    });
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, results, selectedStation, timetableItem,) => {

    return (
    <div key={`JSON.stringify(timetableItem)${__key++}`} className="stations-timetable-item">
        <span className="left">
            {timetableItem.location.name}
        </span>
        <span className="right">
            {GetTime(timetableItem.departureTime)}
        </span>
    </div>
)};

const Markdown = (originString, results, selectedStation) => { 

    let origin = originString.split(", ");
    let relevantResults = {
        agencies: results.agencies,
        stations: []
    };
    results.stations.forEach((station) => {
        relevantResults.stations.push({
            type: station.type,
            agency: station.agency,
            duration: station.duration,
        });
    });
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Additional

Selected Station JSON
\`\`\`js
${JSON.stringify(selectedStation, null, 4)}
\`\`\`

**Calculating the time from milliseconds (since 1970):**
\`\`\`js
GetTimeFromMillis => ${GetTimeFromMillis}
\`\`\`

**Calculating the duration:**
\`\`\`js
GetDuration => ${GetDuration}
\`\`\`

**Parsing the data:**
\`\`\`js
let address = station.location.address; // ${selectedStation.location.address}
let duration = GetDuration(station.duration); // ${GetDuration(selectedStation.duration)}
let availableBicycles = station.additional.availableBicycles; // ${selectedStation.additional.availableBicycles}
let availableDocks = station.additional.availableDocks; // ${selectedStation.additional.availableDocks}
let lastUpdatedAt = GetTimeFromMillis(station.additional.lastUpdatedMillis); // ${GetTimeFromMillis(selectedStation.additional.lastUpdatedMillis)}
\`\`\`

---

`;};

let key=0;
const Timetable = ({
    results,
    origin,
    selectedStation,
}) => {

    let type = GetType(selectedStation?.type, selectedStation?.agency, results.agencies);
    let lastUpdated = GetTimeFromMillis(selectedStation?.additional?.lastUpdatedMillis);


    return (
        selectedStation?.additional != null 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(origin, results, selectedStation)} headerText="Additional" className="stations-timetable-container">
                    <div className="stations-timetable-item" key={`stations-timetable-item${key++}`}>
                        <span className="left">
                            icon:<small>{selectedStation.type} {type}</small>
                            <br/>
                            {selectedStation.location.address}
                        </span>
                        <span className="right">
                            {GetDuration(selectedStation.duration)}
                        </span>

                        <span className="left">
                            {selectedStation?.additional?.availableBicycles} Bicycles
                        </span>
                        <span className="right">
                            {selectedStation?.additional?.availableDocks} Spaces
                        </span>
                        <span className="left"></span>
                        <span className="right">
                            Last Update at {lastUpdated}
                        </span>
                    </div>
                </ExampleCode>
            )
        : null
    );
};

export default Timetable;