import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";

const RenderStops = (leg) => {

    let shouldRenderStops = false;
    switch(leg.mode) {
        case "BUS":
        case "TRAM":
            shouldRenderStops = true;
            break;
        default: break;
    }

    return (
        shouldRenderStops
        ?
        <div className="directions-stops-container">
            <div className="directions-stops-start">
                { leg?.from?.name }
            </div>
            <hr/>
            <b>{leg.stops.length} Stops</b>
            {
                leg.stops.map((stop) => {
                    return (
                        <div className="directions-stops-item" key={JSON.stringify(stop)}>
                            { stop?.location?.name }
                        </div>
                    );
                })
            }
            <hr/>
            <div className="directions-stops-end">
                { leg?.to?.name }
            </div>
        </div>
        : null
    );
};

const GetCostString = (fare, currencies) => {
    
    if(!fare?.currency?.reference || !currencies) { return ""; }

    let currency = currencies[fare.currency.reference];
    let digits = currency.fractionDigits;
    let div = Math.pow(10, digits);

    return `${currency.code} ${Number(fare.value / div).toFixed(digits)}`;
}

const LoopOverResults = (legs, results, tab) => {
    let lines = [];
    legs.forEach((_leg, index) => {
        let leg = {
            mode: _leg.mode,
            name: _leg.name,
            duration: _leg.duration,
            fare: _leg.fare,
            from: _leg.from,
            to: _leg.to,
            stops: _leg.stops,
            polyline: _leg.polyline
        };
        lines.push(`${tab}...`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(leg, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let leg = legs[${index}];`);
        
        lines.push(`${tab}let travel_mode = GetTravelMode(leg.mode); // ${GetTravelMode(leg.mode)}`);
        lines.push(`${tab}let destination = leg.to.name; // ${leg.to.name}`);
        lines.push(`${tab}let platformLine = leg.from.platformLine; // ${leg.from.platformLine}`);
        lines.push(`${tab}let duration = \`\${leg.duration.minutes} min\`; // ${leg.duration.minutes} min`);
        lines.push(`${tab}let fare = GetFare(leg.fare, result.currencies); // ${GetCostString(leg.fare, results.currencies)}`);
        lines.push(`${tab}let text = \`\${travel_mode} to \${destination}\`; // ${GetTravelMode(leg.mode)} to ${leg.to.name}`);
        lines.push("");
        lines.push(`${tab}let stopsFrom = leg.from.name; // ${leg.from.name}`);
        lines.push(`${tab}let stopsTo = leg.to.name; // ${leg.to.name}`);
        lines.push(`${tab}let stopCount = leg.stops ? leg.stops.length : 0; // ${leg.stops ? leg.stops.length : 0}`);
        lines.push(`${tab}let stops = leg.stops; // see JSON above`);
        try {
            //lines.push(`${tab}let start = new Date(route.start); // start(${route.start}) -> ${new Date(route.start)}`);
            //lines.push(`${tab}let end = new Date(route.end); // end(${route.end}) -> ${new Date(route.end)}`);
        } catch(err) {
            console.error(err);
        }
    });
    return lines.join("\n");
};

const GetTravelMode = (mode) => {
    let modeText;
    switch(mode) {
        case "BICYCLE_RENT": 
        case "BICYCLE":
            modeText = "Cycle to"; break;
        case "BUS": modeText = "To"; break;
        case "WALK": modeText = "Walk to"; break;
        case "CAR": modeText = "Drive to"; break;
        case "TRAM": modeText = "To"; break;
        default:
            modeText = "Traverse";
            break;
    }
    return modeText;
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, directionsResult, leg,) => {
    let text;
    let modeText = GetTravelMode(leg.mode);

    leg._key = __key++;
    return (
    <div key={JSON.stringify(leg)} className="directions-legs-item" onClick={onSelect.bind(null, leg)}>
        <span className="left"><nobr><small>icon:{leg.mode}</small></nobr></span>
        <span className="right"></span>
        <span className="left">{` ${modeText} ${leg.to.name}`}</span>
        <span className="right">{leg.duration.minutes} min</span>
        <span className="left">{leg.from.platformLine ? leg.from.platformLine : ""}</span>
        <span className="right"></span>
        { RenderStops(leg) }
    </div>
)};

const Markdown = (results, legs) => { 

    let relevantResults = [];
    legs.forEach((_leg) => {
        let leg = {
            mode: _leg.mode,
            from: _leg.from,
            to: _leg.to,
            duration: _leg.duration,
            fare: _leg.fare,
            polyline: _leg.polyline,
            stops: _leg.stops
        };
        relevantResults.push(leg);
    });
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Route Legs

The data on the right is a sub selection produced by the following GraphQL query:
> Please note:
> that only relevant data is displayed; an ellipsis is used indicate missing fields.

> Please note:
> it is now possible to select suggested routes hierarchically linked to a suggested transport
> and / or select all suggested routes.

\`\`\`graphql
{
	easyDirections(
        ...
    ) {
        agencies { name }
        currencies { code fractionDigits }
        suggestedTransports {
            """ selecting the suggested routes here would result in the hierarchically linked suggested routes """
            suggestedRoutes { 
                ...
            }
        }
        """ selecting the suggested routes here would result in all suggested routes """
        suggestedRoutes { 
            ...
            legs { 
                from { name, platformLine }
                to { name, platformLine }
                agency { reference, name }
                steps { ... }
                stops {
                    location {
                      name
                      coordinates { latitude longitude }
                    }
                }
                mode 
                polyline 
                fare { 
                    value 
                    currency { reference } 
                }
                duration { minutes }
                carbonEmissions
            }
        }
    }
}
\`\`\`

The query above, and sub selection, produced this result:
\`\`\`json
${relevantJson}
\`\`\`

**Object references:**

Some object types are referencable; they are included as a top level array where the reference field is a numeric index.
The following object types are referenced here:
 * currency

> Using this method is opt-in and you may choose to include the fields of the referenced objects directly.

**Calculating the travel modes**
\`\`\`js
GetTravelMode => ${GetTravelMode}
\`\`\`

**Looping over the result:**
\`\`\`js
for(let i = 0; i < legs.length; i++) {
${LoopOverResults(legs, results, "\t")}    
}
\`\`\`

.
`;};

const Legs = ({
    results,
    legs,
    onSelect,
}) => {

    let [currentResults, setCurrentResults] = React.useState(legs);

    useEffect(() => {
        setCurrentResults(legs);
    }, [legs]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        setSelectedIndex(index);
        if(onSelect) {
            onSelect(item);
        }
    };

    return (
        currentResults?.length > 0 
        ?   (
                <ExampleCode showCodeTo="left" markdown={Markdown(results, legs)} headerText="Route Legs" className="directions-legs-container">
                    { currentResults.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default Legs;