import React from "react";

const SelectMode = ({
    onSelectMode,
}) => {

    let [currentMode, setCurrentMode] = React.useState("directions");

    const _onSelectMode = (mode) => {
        setCurrentMode(mode);
        if(onSelectMode) {
            onSelectMode(mode);
        }
    };

    return (
        <div className="app-mode-container">
            <input
                type="radio"
                name="app-mode"
                onChange={_onSelectMode.bind(null, "directions")}
                checked={currentMode === "directions"}>
            </input> Directions
            <input
                type="radio"
                name="app-mode"
                onChange={_onSelectMode.bind(null, "stations")}
                checked={currentMode === "stations"}>
            </input> Stations
            <input
                type="radio"
                name="app-mode"
                onChange={_onSelectMode.bind(null, "lines")}
                checked={currentMode === "lines"}>
            </input> Lines
        </div>
    );
};

export default SelectMode;