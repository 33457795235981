import mapboxgl from 'mapbox-gl';
import * as Markers from "./Markers.js";
import { EasyQuery } from "../Search/EasyQuery.js";
import { EasyStations } from "../Search/EasyStations.js";
import * as EasyLines from "../Search/EasyLines.js";
import polyline from "@mapbox/polyline";
import * as MapboxCircle from "mapbox-gl-circle";

const reveserLineItem = (item) => [item[1], item[0]];
const reverseLine = (line) => line.map(reveserLineItem);
const getColor = (leg) => {
    switch(leg.mode) {
        case "foot": 
        case "walk":
            return "red";
        case "bus": return "green";
        case "tram": return "yellow";
        default: return "lightblue";
    }
};

const OriginCircleFromOrigin = (origin, defaultRadiusInMeters=2000) => {

    let {lat, lng} = origin.marker.getLngLat();
    let originCircle = new MapboxCircle(
        { lat, lng },
        defaultRadiusInMeters,
        {
            editable: true,
            minRadius: 100,
            fillColor: '#29AB87'
        }
    );
    return originCircle;
}

export class State {

    setOriginSearchValueRef = null;
    setDestinationSearchValueRef = null;

    selectedMode = "directions";
    map = null;
    placeOn = null;
    origin = null;
    originCircle = null;
    originCircleEvents = false;
    originCircleOnMap = false;
    destination = null;
    layerIds = [];
    sourceIds = [];
    markers = [];
    idCounter = 0;
    
    onStationResults = null;
    onLineResults = null;
    onLineResults_line = null;
    onTripResults = null;

    onUpdateOriginLocation = null;
    onUpdateDestinationLocation = null;
    
    onUpdateStationSearchRadius = null;
    onUpdateLineSearchRadius = null;
    
    tripLegs = [];
    transportTypes = [];

    stationFilterType = null;
    stationQueryLimits = null;

    lineFilterType = null;
    lineQueryLimits = null;

    constructor({onTripResults, onUpdateOriginLocation, onUpdateDestinationLocation, onUpdateStationSearchRadius, onUpdateLineSearchRadius, setOriginSearchValueRef, setDestinationSearchValueRef, onStationResults, onLineResults, onLineResults_line, onLineResults_station}) {
        this.onTripResults = onTripResults;
        this.onUpdateOriginLocation = onUpdateOriginLocation;
        this.onUpdateDestinationLocation = onUpdateDestinationLocation;
        this.onUpdateStationSearchRadius = onUpdateStationSearchRadius;
        this.onUpdateLineSearchRadius = onUpdateLineSearchRadius;
        this.setOriginSearchValueRef = setOriginSearchValueRef;
        this.setDestinationSearchValueRef = setDestinationSearchValueRef;
        this.onStationResults = onStationResults;
        this.onLineResults = onLineResults;
        this.onLineResults_line = onLineResults_line;
        this.onLineResults_station = onLineResults_station;
    }

    setMap(map) {
        this.map = map;
    }

    setMode(mode) {
        this.selectedMode = mode;

        // actually, on mode change
        // clear everything
        this.clear();

        if(mode === "stations") {
            // then remove origin / destination (markers)
            //if(this.origin) {
            //    this.origin.marker.remove();
            //}
            if(this.destination) {
                this.destination.marker.remove();
            }

            // and display the origin circle instead
            if(this.origin) {
                if(!this.originCircle) {
                    this.originCircle = OriginCircleFromOrigin(
                        this.origin,
                        this.stationQueryLimits?.radius_m > 0 ? this.stationQueryLimits.radius_m : 2000
                    );
                }
            }
            this.addOriginCircleToMap();
        }

        if(mode === "directions") {

            // remove the origin circle
            this.removeOriginCircle();

            // then display the origin / destination (markers)
            if(this.origin) {
                this.origin.marker.addTo(this.map);
            }
            if(this.destination) {
                this.destination.marker.addTo(this.map);
            }
        }

        this.query();
    }

    updateOriginCircle() {
        // from the origin
        if(!this.origin) { return; }

        let radius = 2000;
        switch(this.selectedMode) {
            case "stations":
                radius = this.stationQueryLimits?.radius_m > 0 ? this.stationQueryLimits?.radius_m : 2000;
                break;
            case "lines":
                radius = this.lineQueryLimits?.radius_m > 0 ? this.lineQueryLimits?.radius_m : 2000;
                break;
        }

        if(!this.originCircle) {
            this.originCircle = OriginCircleFromOrigin(
                this.origin,
                radius
            );
        }
        
        if(["stations", "lines"].indexOf(this.selectedMode) >= 0) {
            this.originCircle.setCenter(this.origin.marker.getLngLat());
            this.originCircle.setRadius(radius);
            this.addOriginCircleToMap();
        }
    }

    addOriginCircleToMap() {
        if(!this.originCircle) { return; }
        if(this.originCircleOnMap === true) { return; }
        this.originCircleOnMap = true;
        this.originCircle.addTo(this.map);
        if(this.originCircleEvents === false) {
            this.originCircleEvents = true;
            this.originCircle.on("centerchanged", (circle) => {
                if(this.origin) {
                    this.origin.marker.setLngLat(circle.getCenter());
                }
                this.query();
            });
            this.originCircle.on("radiuschanged", (circle) => {
                let updateRadiusFn;
                switch(this.selectedMode) {
                    case "stations": updateRadiusFn = this.onUpdateStationSearchRadius; break;
                    case "lines": updateRadiusFn = this.onUpdateLineSearchRadius; break;
                }
                if(updateRadiusFn) {
                    updateRadiusFn(circle.getRadius());
                }
            });
        }
    }

    removeOriginCircle() {
        if(!this.originCircle) { return; }
        if(this.originCircleOnMap === false) { return; }
        this.originCircleOnMap = false;
        this.originCircle.remove();
    }

    async query() {
        switch(this.selectedMode) {
            case "directions":
                await this.queryTrips();
                break;
            case "stations":
                await this.queryStations();
                break;
            case "lines":
                await this.queryLines();
                break;
        }
    }

    async queryStations() {
        if(!this.origin) { 
            return; 
        }

        let response = await EasyStations({
            coordinates: this.origin,
            stationFilter: this.stationFilterType,
            queryLimits: this.stationQueryLimits
        });

        if(this.onStationResults && response?.data?.easyStations) {
            this.onStationResults(response.data.easyStations);
        }
    }

    async queryLines() {
        if(!this.origin) { 
            return; 
        }

        let response = await EasyLines.Lines({
            coordinates: this.origin,
            filter: this.lineFilterType,
            queryLimits: this.lineQueryLimits
        });

        if(this.onLineResults && response?.data?.easyLines) {
            this.onLineResults(response.data.easyLines);
        }
    }

    async queryLines_Line(line) {
        if(!this.origin) { 
            return; 
        }

        let response = await EasyLines.Line({
            coordinates: this.origin,
            line: line.iref
        });

        if(this.onLineResults_line && response?.data?.easyLines) {
            this.onLineResults_line(response.data.easyLines);
        }
    }

    async queryLines_Station(station) {

        console.log(`Mapbox/State/queryLines_Station: ${JSON.stringify(station)}`);
        if(!this.origin) { 
            return; 
        }

        let response = await EasyLines.Station({
            coordinates: this.origin,
            station
        });

        if(this.onLineResults_station && response?.data?.easyLines) {
            this.onLineResults_station(response.data.easyLines);
        }
    }

    async queryTrips() {
        if(!this.destination || !this.origin || !(this.transportTypes.length > 0)) { 
            return; 
        }

        // query local server to see
        let response = await EasyQuery({
            transportTypes: this.transportTypes,
            from: {
                latitude: this.origin.latitude,
                longitude: this.origin.longitude
            },
            to: {
                latitude: this.destination.latitude,
                longitude: this.destination.longitude
            }
        });

        let easyDirections = response.data.easyDirections;
        if(this.onTripResults) {
            this.onTripResults(easyDirections);
        }
        /*
        if(trips.length > 0) {
            this.selectTrip(trips[0]);
        } else {
            this.clearTrip();
        }
        */
    }

    clearTrip() {
        this.tripLegs.forEach((leg) => {
            this.removeLayer(leg.layerId);
            this.removeSource(leg.sourceId);
        });
        this.tripLegs = [];
    }

    selectTrip(trip) {
            
        // clear the previous ones
        this.clearTrip();

        // draw the new ones
        this.tripLegs = trip.legs.map((leg) => {
            let color = getColor(leg);
            let line = polyline.decode(leg.pointsOnLink.points);
            let sourceId = this.addSource({
                type: "geojson",
                data: {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: reverseLine(line)
                    }
                }
            });
            let layer = this.addLayer(sourceId, {
                type: "line",
                layout: {
                    "line-join": "round",
                    "line-cap": "round"
                },
                paint: {
                    "line-color": color,
                    "line-width": 3
                }
            });
            return {
                color,
                line,
                sourceId,
                layerId: layer.id
            }
        });
    }

    selectOrigin(longitude, latitude) {
        if(!this.origin) {
            const updateOriginLocation = () => {
                if(this.onUpdateOriginLocation) {
                    this.onUpdateOriginLocation([this.origin.longitude, this.origin.latitude]);
                    this.updateOriginCircle();
                }
                this.query();
            };
            this.origin = newMarkerToMap(this.map, "red", longitude, latitude, updateOriginLocation);
            this.updateOriginCircle();
        } else {
            this.origin.marker.setLngLat([longitude, latitude]);
        }
        this.map.flyTo({
            center: [longitude, latitude],
        });
        this.query();
    }

    voidOrigin() {
        if(!this.origin) { return; }
        this.origin.marker.remove();
        this.origin = null;
    }

    selectDestination(longitude, latitude) {
        if(!this.destination) {
            const updateDestinationLocation = () => {
                if(this.onUpdateDestinationLocation) {
                    this.onUpdateDestinationLocation([this.destination.longitude, this.destination.latitude]);
                }
                this.query();
            };
            this.destination = newMarkerToMap(this.map, "black", longitude, latitude, updateDestinationLocation);
        } else {
            this.destination.marker.setLngLat([longitude, latitude]);
        }
        this.map.flyTo({
            center: [longitude, latitude],
        });
        this.query();
    }

    voidDestination() {
        if(!this.destination) { return; }
        this.destination.marker.remove();
        this.destination = null;
    }

    selectLongitudeLatitude(longitude, latitude) {
        if(this.origin && this.destination) { return; }

        let color;
        let resultFor = null;
        if(!this.origin) {
            color = "red";
            resultFor = "origin";
        } else
        if(!this.destination) {
            color = "black";
            resultFor = "destination";
        }

        const updateOriginDestinationLocation = () => {
            switch(resultFor) {
                case "origin":
                    this.setOriginSearchValueRef.setCall("");
                    if(this.onUpdateOriginLocation) {
                        this.onUpdateOriginLocation([this.origin.longitude, this.origin.latitude]);
                        this.updateOriginCircle();
                    }
                    break;
                case "destination":
                    this.setDestinationSearchValueRef.setCall("");
                    if(this.onUpdateDestinationLocation) {
                        this.onUpdateDestinationLocation([this.destination.longitude, this.destination.latitude]);
                    }
                    break;
            }
            this.query();
        };

        let item = newMarkerToMap(this.map, color, longitude, latitude, updateOriginDestinationLocation);

        if(!this.origin) {
            this.origin = item;
            this.updateOriginCircle();
        } else 
        if(!this.destination) {
            this.destination = item;
        }
        this.query();
        return resultFor;
    }

    removeSource(sourceId) {
        this.map.removeSource(sourceId);
        let idx = this.sourceIds.indexOf(sourceId);
        this.sourceIds = [this.sourceIds.slice(0, idx), ...this.sourceIds.slice(idx + 1, this.sourceIds.length)];
    }

    removeLayer(layerId) {
        this.map.removeLayer(layerId);
        let idx = this.layerIds.indexOf(layerId);
        this.layerIds = [this.layerIds.slice(0, idx), ...this.layerIds.slice(idx + 1, this.layerIds.length)];
    }

    addSource(props) {
        let sourceId = `source-${this.idCounter++}`;
        let source = {
            ...props
        };
        this.map.addSource(sourceId, source);
        this.sourceIds.push(sourceId);
        return sourceId;
    }

    addLayer(sourceId, props) {
        let layerId = `layer-${this.idCounter++}`;
        let layer = {
            'id': layerId,
            'source': sourceId,
            ...props
        }
        this.map.addLayer(layer);
        this.layerIds.push(layerId);
        return layer;
    }

    clear() {
        // remove layers / sources
        this.layerIds.forEach((e) => this.map.removeLayer(e));
        this.sourceIds.forEach((e) => this.map.removeSource(e));
        this.markers.forEach((m) => m.marker.remove());

        // clear state variables
        this.sourceIds = [];
        this.layerIds = [];
        this.marker = [];
    }

    setTransportTypes(transportTypes) {
        this.transportTypes = transportTypes;
        this.query();
    }

    setStationFilterType(stationFilterType) {
        this.stationFilterType = stationFilterType;
        this.query();
    }

    setLineFilterType(lineFilterType) {
        this.lineFilterType = lineFilterType;
        this.query();
    }

    setStationQueryLimits(queryLimits) {
        this.stationQueryLimits = queryLimits;
        this.updateOriginCircle();
        this.query();
    }

    setLineQueryLimits(queryLimits) {
        this.lineQueryLimits = queryLimits;
        this.updateOriginCircle();
        this.query();
    }

    setSelectedLine(line) {
        this.queryLines_Line(line);
    }

    setSelectedLineStation(station) {
        this.queryLines_Station(station);
    }

    newMarker(color, longitude, latitude, updateFn, draggable=true) {
        let marker = newMarkerToMap(this.map, color, longitude, latitude, updateFn, draggable);
        this.markers.push(marker);
        return marker;
    }
}

const newMarkerToMap = (map, color, longitude, latitude, updateFn, draggable=true) => {

    // place the marker
    let marker = new mapboxgl.Marker({ color, draggable })
        .setLngLat([longitude, latitude])
        .addTo(map)
    ;

    let item = {
        color,
        latitude,
        longitude,
        marker
    };

    const onDragEnd = () => {
        const lngLat = marker.getLngLat();
        item.latitude = lngLat.lat;
        item.longitude = lngLat.lng;
        updateFn();
    };
    marker.on("dragend", onDragEnd);
    return item;
};