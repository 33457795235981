import React, { useRef, useEffect } from 'react';
import Autocomplete from "./AutoComplete.js";
import { Geocode } from "./Geocode.js";

const debounce = (func, delay, immediate) => {
    let timerId;
    return (...args) => {
        const boundFunc = func.bind(this, ...args);
        clearTimeout(timerId);
        if (immediate && !timerId) {
            boundFunc();
        }
        const calleeFunc = immediate ? () => { timerId = null } : boundFunc;
        timerId = setTimeout(calleeFunc, delay);
    }
}

const Search = ({
    appMode,
    setOriginSearchValueHook,
    originPlaceholder,
    onOriginSelect,
    onOriginVoid,
    setDestinationSearchValueHook,
    destinationPlaceholder,
    onDestinationSelect,
    onDestinationVoid
}) => {

    const [originState, setOriginState] = React.useState({
        value: "",
        items: []
    });
    const onOriginChange = async (value) => { 
        let searchResult = await Geocode({ query: value });
        let items = searchResult?.data?.geocode?.results;
        setOriginState({items: items ? items : []});
    };
    const _onOriginSelect = (item) => { onOriginSelect(item); };
    const onOriginClear = () => { setOriginState({items:[]});};

    const [destinationState, setDestinationState] = React.useState({
        value: "",
        items: []
    });
    const onDestinationChange = async (value) => {
        let searchResult = await Geocode({ query: value });
        let items = searchResult?.data?.geocode?.results;
        setDestinationState({items: items ? items : []});
    };
    const _onDestinationSelect = (item) => { onDestinationSelect(item); };
    const onDestinationClear = () => { setDestinationState({items:[]});};

    return (
        <div className="search-container">
            <form>
                {
                    ["directions", "stations"].indexOf(appMode) > -1
                        ?   <div className="search-container__item">
                                <Autocomplete 
                                    setValueHook={setOriginSearchValueHook}
                                    placeholder={originPlaceholder}
                                    items={originState.items} 
                                    onChangeEvent={onOriginChange} 
                                    onSelectEvent={_onOriginSelect} 
                                    onClearRequest={onOriginClear}
                                    onVoid={onOriginVoid}
                                />
                            </div>
                        :   null
                }
                {
                    ["directions"].indexOf(appMode) > -1
                    ?   <div className="search-container__item">
                            <Autocomplete 
                                setValueHook={setDestinationSearchValueHook}
                                placeholder={destinationPlaceholder}
                                items={destinationState.items} 
                                onChangeEvent={onDestinationChange} 
                                onSelectEvent={_onDestinationSelect} 
                                onClearRequest={onDestinationClear}
                                onVoid={onDestinationVoid}
                            />
                        </div>
                    :   null
                }
            </form>
        </div>
    );
};

export default Search;