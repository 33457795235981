import React, { useEffect } from 'react';

const distance = item => Number(Math.round(item.walkDistance) / 1000).toFixed(2) + " km";
const mode = item => [...new Set(item.legs.map(leg => leg.mode))].join (" / ");

const _onSelect = (callback, item, index, event) => {
    if(!callback || !event?.target?.checked) { return; }
    callback(item, index);
};

const RenderItem = (onSelect, selectedIndex, item, index) => (
    <li key={JSON.stringify(item)} className="trip-results-container__item">
        <input type="radio" name="trip-plan-results" value={`${index}`} onChange={_onSelect.bind(null, onSelect, item, index)} checked={selectedIndex === index}></input>
        <span>{distance(item)} - {mode(item)} - </span>
    </li>
);

const TripResults = ({
    tripResults,
    onSelect,
}) => {

    let [currentResults, setCurrentResults] = React.useState(tripResults);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        setSelectedIndex(index);
        onSelect(item);
    };

    useEffect(() => {
        setCurrentResults(tripResults);
        setSelectedIndex(0);
    }, [tripResults]);

    return (
        currentResults?.length > 0 ?
        <div className="trip-results-container">
            <ul>
            { currentResults.map(RenderItem.bind(null, onItemSelect, selectedIndex)) }
            </ul>
        </div>
        : null
    );
};

export default TripResults;