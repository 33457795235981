import * as Components from "./components";
import './App.css';

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const mapboxStyle = process.env.REACT_APP_MAPBOX_STYLE;

const centerFn = () => {
  let location = process.env.REACT_APP_ZOOM_LOCATION;
  let tokens = location.split(",");
  let latitude = Number.parseFloat(tokens[0]);
  let longitude = Number.parseFloat(tokens[1]);
  return [latitude, longitude];
};
const center = centerFn();

function App() {
  return (
    <div className="App">
      <Components.Mapbox 
                accessToken={mapboxAccessToken}
                style={mapboxStyle}
                center={center}
                zoom={14}
            />
    </div>
  );
}

export default App;
