import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const GetDistance = (distance) => {
    let tokens = [];
    if(distance.km > 0) { tokens.push(`${distance.km} km`); }
    if(distance.m > 0) { tokens.push(`${distance.m} m`); }
    return tokens.join(" ");
};

const GetType = (type, agency, agencies) => {
    let result = null;
    let agencyReference = null;
    switch(type) {
        case "BUS": result = "Bus"; break;
        case "TRAM": result = "Tram"; break;
        default:
            result = type;
            break;
    }

    if(agencyReference !== null) {
        result = agencies[agencyReference].name;
    }
    return result;
};

const LoopOverResults = (results, tab) => {
    try {
    let lines = [];
    
    results.filtered[0].stations.filter((_,index) => index < 5).forEach((_filtered, index) => {

        let station = _filtered;
        lines.push(`${tab}...`);
        
        lines.push(`${tab}/*\n${tab}${JSON.stringify(station, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let station = stations[${index}]; // see json above`);
        
        lines.push(`${tab}let icon = station.type; // ${station.type}`);
        lines.push(`${tab}let type = GetType(station.type, station.agency, agencies); // ${GetType(station.type, station.agency, results.agencies)}`);
        lines.push(`${tab}let address = station.location.address; // ${station.location.address}`);
        lines.push(`${tab}let distance = GetDistance(station.distance); // ${GetDistance(station.distance)}`);
    });
    return lines.join("\n");
    } catch(err) {
        console.error(err);
        return ``;
    }
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, results, station,) => {

    let name = station.location.name;
    let address = station.location.address;

    return (
    <div key={JSON.stringify(station)} className="station-item" onClick={onSelect.bind(null, station)}>
        <span className="left">
            <b>{address}</b>
        </span>
        <span className="right">
            {`${station.distance.km > 0 ? `${station.distance.km} km ` : ''}${station.distance.m} m`}
        </span>
    </div>
)};

const Markdown = (originString, selectedLine, results) => { 

    //if(true) { return ""; }

    let origin = originString.split(", ");
    let relevantResults = {
        filtered: []
    };
    results.filtered.forEach((route) => {
        let stations = route.stations.filter((_, index) => index < 5);
        //route.stations = stations;
        relevantResults.filtered.push({ ...route, stations });
    });
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Line

The data on the right was retrieved using the following GraphQL query:

\`\`\`graphql
{
    easyLines(
        coordinates: {
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        }
        line: "${selectedLine.iref}"
    ) {
        filtered {
            iref
            polyline
            name
            location { address }
            distance { m, km }
            duration { formatted { minutes seconds } }
            type
            agency { name }
            stations {
                iref
                type
                location { address, coordinates { latitude, longitude } }
                distance { m, km }
            }
        }
    }
}
\`\`\`

The query above produced this result:
> Please note: stations have been limited to five (5) records
\`\`\`json
${relevantJson}
\`\`\`

**Get the type label:**
\`\`\`js
GetType => ${GetType}
\`\`\`

**Calculating the distance:**
\`\`\`js
GetDistance => ${GetDistance}
\`\`\`


**Looping over the result:**
\`\`\`js
for(let i = 0; i < filtered[0].stations.length; i++) {
${LoopOverResults(results, "\t")}    
}
\`\`\`

---

.
`;};

let _key=0;
const Line = ({
    results,
    origin,
    selectedLine,
    onSelect,
}) => {

    let [currentResults, setCurrentResults] = React.useState(results);

    useEffect(() => {
        setCurrentResults(results);
    }, [results]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        onSelect(item);
    };

    return (
        results?.filtered?.length > 0 
        ?   (
                <ExampleCode showCodeTo="left" markdown={Markdown(origin, selectedLine, results)} headerText="Line" className="line-container">
                    <div className="line-item" key={`line-item${_key++}`}>
                        <span className="left">
                            icon:<small>{selectedLine.type} {selectedLine.name}</small>
                            <br/>
                            {selectedLine.location.address}
                        </span>
                        <span className="right"></span>
                    </div>
                    { results?.filtered?.[0]?.stations.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default Line;