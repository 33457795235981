// eslint-disable-next-line
import mapboxgl from '!mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

import React, { useRef, useEffect } from 'react';
import Search from "../Search/index";
import { State } from "./State";
import TripResults from "../TripResults/index.js";
import Directions from "../Directions/index";
import EasyStations from "../Stations/index";
import EasyLines from "../Lines/index";
import SelectMode from "./SelectMode";

const Mapbox = ({
  accessToken,
  mapData,
  style,
  messageOverlay,
  center,
  zoom
}) => {

  mapboxgl.accessToken = accessToken;
  const styleUrl = style ? style : "mapbox://styles/mapbox/streets-v11";

  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [tripResults, setTripResults] = React.useState([]);
  const [stations, setStations] = React.useState([]);
  
  const [directions, setDirections] = React.useState(null);

  const [lines, setLines] = React.useState([]);
  const [linesLine, setLinesLine] = React.useState([]);
  const [lineStation, setLinesStation] = React.useState([]);

  const onTripResults = (results) => { 
    setTripResults(results);
    //setDirections(results);
  };

  const onStationResults = (results) => {
    setStations(results);
  };

  const onLineResults = (results) => {
    setLines(results);
  }
  const onLineResults_line = (results) => {
    setLinesLine(results);
  }
  const onLineResults_station = (results) => {
    setLinesStation(results);
  }

  const [searchOriginPlaceholder, setSearchOriginPlaceholder] = React.useState("Origin");
  const [searchDestinationPlaceholder, setSearchDestinationPlaceholder] = React.useState("Destination");
  const [searchStationRadius, setSearchStationRadius] = React.useState(null);
  const [searchLineRadius, setSearchLineRadius] = React.useState(null);
  const coordinatesResult = (coordinates) => [new Number(coordinates[0]).toFixed(6), new Number(coordinates[1]).toFixed(6)].join(", ");

  const onUpdateOriginLocation = (coordinates) => {
    setSearchOriginPlaceholder(coordinatesResult(coordinates));
  };
  const onUpdateDestinationLocation = (coordinates) => {
    setSearchDestinationPlaceholder(coordinatesResult(coordinates));
  };

  const onUpdateStationSearchRadius = (radius) => {
    setSearchStationRadius(radius);
  };

  const onUpdateLineSearchRadius = (radius) => {
    setSearchLineRadius(radius);
  };

  //let [transportTypes, setTransportTypes] = React.useState([]);
  const onTransportTypes = (transportTypes) => {
      mapState.current.setTransportTypes(transportTypes);
  };

  const onStationFilterType = (stationFilterType) => {
    mapState.current.setStationFilterType(stationFilterType);
  };

  const onLineFilterType = (lineFilterType) => {
    mapState.current.setLineFilterType(lineFilterType);
  };

  const onStationQueryLimits = (queryLimits) => {
    mapState.current.setStationQueryLimits(queryLimits);
  };

  const onLineQueryLimits = (queryLimits) => {
    mapState.current.setLineQueryLimits(queryLimits);
  };

  //
  const setOriginSearchValueRef = React.useRef({
    setCall: null
  });

  const setDestinationSearchValueRef = React.useRef({
    setCall: null
  });

  const mapState = useRef(new State({
    onTripResults, onStationResults, onLineResults, onLineResults_line, onLineResults_station, onUpdateOriginLocation, onUpdateDestinationLocation, onUpdateStationSearchRadius, onUpdateLineSearchRadius, setOriginSearchValueRef: setOriginSearchValueRef.current, setDestinationSearchValueRef: setDestinationSearchValueRef.current,
  }));

  const onOriginSelect = (item) => { 
    setSearchOriginPlaceholder(coordinatesResult([item.longitude, item.latitude]));
    mapState.current.selectOrigin(item.longitude, item.latitude); 
  };
  const onOriginVoid = () => { 
    setSearchOriginPlaceholder("Origin");
    mapState.current.voidOrigin(); 
  };

  const onDestinationSelect = (item) => { 
    setSearchDestinationPlaceholder(coordinatesResult([item.longitude, item.latitude]));
    mapState.current.selectDestination(item.longitude, item.latitude); 
  };
  const onDestinationVoid = () => { 
    setSearchDestinationPlaceholder("Destination");
    mapState.current.voidDestination(); 
  };

  const onSelectTripResult = (tripResult) => {
    mapState.current.selectTrip(tripResult);
    setDirections(tripResult.legs);
  };


  const [selectedTransport, setSelectedTransport] = React.useState(null);
  const onSelectTransport = (transport) => {
    setSelectedTransport(transport);
  };

  const [selectedStationFilter, setSelectedStationFilter] = React.useState(null);
  const onSelectedStationFilter = (stationFilter) => {

  };

  const onDefaultTest = () => {
    onOriginSelect({ latitude: 20.673846, longitude: -103.335472});
    onDestinationSelect({ latitude: 20.651332, longitude: -103.364010});
  };

  const onTest2 = () => {
    onOriginSelect({ latitude: 20.653505, longitude: -103.393600});
    onDestinationSelect({ latitude: 20.653827, longitude: -103.399965});
  };

  const onTest3 = () => {
    onOriginSelect({ latitude: 20.704869, longitude: -103.3279765 });
    onDestinationSelect({ latitude: 20.693579, longitude: -103.416145 });
  }

  const [appMode, setAppMode] = React.useState("directions");
  const onAppModeSelect = (mode) => {
    setAppMode(mode);
    mapState.current.setMode(mode);
  };

  // Initialize map when component mounts
  useEffect(() => {

    // initialize map only once
    if (!map.current && !messageOverlay) {
      // initialise map component
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: styleUrl,
      });
      mapState.current.setMap(map.current);
      // Add navigation control (the +/- zoom buttons)
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
      // once loaded, populate the geometry
      map.current.on('load', () => {
        setIsMapLoaded(true);
        map.current.flyTo({
          center: center,
          zoom: zoom
        });
      });
      map.current.on('click', (evt) => {
        let resultFor = mapState.current.selectLongitudeLatitude(evt.lngLat.lng, evt.lngLat.lat);
        let result = coordinatesResult([evt.lngLat.lng, evt.lngLat.lat]);
        switch(resultFor) {
          case "origin": setSearchOriginPlaceholder(result); break;
          case "destination": setSearchDestinationPlaceholder(result); break;
        }
      });
      // once map is idle set busy to false
      map.current.on('idle', () => {
        setIsBusy(false);
      });
    }
  });

  return (
    <div className="mapbox-container">
      <TripResults tripResults={tripResults} onSelect={onSelectTripResult}/>
      <SelectMode onSelectMode={onAppModeSelect}/>
      {appMode === "directions" ?
        <div>
          <div className="test-buttons-container">
            <button onClick={onDefaultTest}>Default Test</button>
            <button onClick={onTest2}>Test 2</button>
            <button onClick={onTest3}>Test 3</button>
          </div>
          <Directions 
            mapState={mapState.current}
            onTransportTypes={onTransportTypes}
            onSelectTransport={onSelectTransport}
            onSelect={()=>{}} 
            directionsResult={tripResults ? tripResults.suggestedTransports : []} 
            suggestedTransports={tripResults ? tripResults.suggestedTransports : []}
            suggestedRoutes={tripResults ? tripResults.suggestedRoutes : []}
            origin={searchOriginPlaceholder} 
            destination={searchDestinationPlaceholder} 
            results={tripResults}
            />
        </div>
        : null
      }
      {appMode === "stations" ?
        <div>
          <EasyStations 
            searchRadius={searchStationRadius}
            onQueryLimits={onStationQueryLimits}
            onFilterType={onStationFilterType}
            mapState={mapState.current}
            onSelect={()=>{}} 
            origin={searchOriginPlaceholder} 
            results={stations} 
            />
        </div>
        : null
      }
      {appMode === "lines" ?
        <div>
          <EasyLines 
            searchRadius={searchLineRadius}
            onQueryLimits={onLineQueryLimits}
            onFilterType={onLineFilterType}
            mapState={mapState.current}
            onSelect={()=>{}} 
            origin={searchOriginPlaceholder} 
            results={lines} 
            lineResults={linesLine}
            stationResults={lineStation}
            />
        </div>
        : null
      }
      <Search 
            appMode={appMode}
            setOriginSearchValueHook={setOriginSearchValueRef.current}
            originPlaceholder={searchOriginPlaceholder}
            onOriginSelect={onOriginSelect} 
            onOriginVoid={onOriginVoid}
            setDestinationSearchValueHook={setDestinationSearchValueRef.current}
            destinationPlaceholder={searchDestinationPlaceholder}
            onDestinationSelect={onDestinationSelect}
            onDestinationVoid={onDestinationVoid}
            />
      <div className="mapbox-block" data-qa-id="c-mapbox">
        <div ref={mapContainer} className="absolute top right left bottom" />
      </div>
    </div>
  );
};

export default Mapbox;