import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const GetCostString = (fare, currencies) => {
    
    if(!fare) { return ""; }

    let currency = currencies[fare.currency.reference];
    let digits = currency.fractionDigits;
    let div = Math.pow(10, digits);
    
    if(fare.unknown === true && fare.maximum > 0) {
        return `${currency.code} ${Number(fare.maximum / div).toFixed(digits)} - Unknown`;
    } else if(fare.unknown === true) {
        return `Unknown`;
    }

    if(fare.free === true && fare.maximum > 0) {
        return `Free - ${currency.code} ${Number(fare.maximum / div).toFixed(digits)}`;
    } else if(fare.free === true) {
        return `Free`;
    }

    if(fare.minimum === fare.maximum) {
        return `${currency.code} ${Number(fare.minimum / div).toFixed(digits)}`;
    }

    return `${currency.code} ${Number(fare.minimum / div).toFixed(digits)} - ${Number(fare.maximum / div).toFixed(digits)}`;
}

//const GetSuggestedRoute = ()

const GetDuration = (duration) => 
    duration.minimum.minutes === duration.maximum.minutes 
    ? duration.minimum.minutes 
    : `${duration.minimum.minutes} - ${duration.maximum.minutes}`
;

const GetTravelMode = (mode, agency, agencies) => {
    let travelMode = null;
    let agencyReference = null;
    switch(mode) {
        case "BICYCLE_RENT": 
            travelMode = agency?.name; 
            agencyReference = agency?.reference;
            break;
        case "BICYCLE": travelMode = "Bike"; break;
        case "BUS": travelMode = "Bus"; break;
        case "WALK": travelMode = "Foot"; break;
        case "CAR": travelMode = "Car"; break;
        case "TRAM": travelMode = "Tram"; break;
        default:
            travelMode = mode;
            break;
    }

    if(agencyReference !== null) {
        travelMode = agencies[agencyReference].name;
    }
    return travelMode;
};

const LoopOverResults = (results, tab) => {
    let lines = [];
    results.suggestedTransports.forEach((suggestedTransport, index) => {
        let transport = {
            mode: suggestedTransport.mode,
            agency: suggestedTransport.agency,
            duration: suggestedTransport.duration,
            fare: suggestedTransport.fare,
            suggestedRoute: suggestedTransport.suggestedRoute
        };
        lines.push(`${tab}...`);
        lines.push(`${tab}/*\n${tab}${JSON.stringify(transport, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let transport = transports[${index}];`);
        
        lines.push(`${tab}let icon = transport.mode; // ${suggestedTransport.mode}`);
        lines.push(`${tab}let travel_mode = "By " + GetTravelMode(transport.mode, transport.agency, result.agencies); // By ${GetTravelMode(suggestedTransport.mode, suggestedTransport.agency, results.agencies)}`);
        lines.push(`${tab}let duration = GetDuration(transport.duration) + " min"; // ${GetDuration(suggestedTransport.duration)} min`);
        lines.push(`${tab}let fare = GetFare(transport.fare, result.currencies); // ${GetCostString(suggestedTransport.fare, results.currencies)}`);

        let suggestedRoute = results.suggestedRoutes[transport.suggestedRoute.reference];
        suggestedRoute = {
            carbonEmissions: suggestedRoute.carbonEmissions,
            start: suggestedRoute.start,
            end: suggestedRoute.end,
            duration: suggestedRoute.duration,
        };
        let suggestedRouteJSON = JSON.stringify(suggestedRoute, null, 4);
        suggestedRouteJSON = `{\n...${suggestedRouteJSON.substring(
            suggestedRouteJSON.indexOf("{") + 1,
            suggestedRouteJSON.lastIndexOf("}")
        )}...\n}`;
        lines.push(`${tab}/*\n${tab}${suggestedRouteJSON.replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let suggestedRoute = result.suggestedRoutes[transport.suggestedRoute.reference]; // see JSON above`);
    });
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, directionsResult, suggestedTransportItem,) => {
    let travelMode = GetTravelMode(suggestedTransportItem.mode, suggestedTransportItem?.agency, directionsResult.agencies);

    return (
    <div key={JSON.stringify(suggestedTransportItem)} className="directions-suggested-transports-item" onClick={onSelect.bind(null, suggestedTransportItem)}>
        {<span className="left">icon:<small>{suggestedTransportItem.mode}</small> </span>}
        <span className="right">
            {GetDuration(suggestedTransportItem?.duration)} min
        </span>
        {<span className="left">By {travelMode} </span>}
        {<span className="right"><nobr>{GetCostString(suggestedTransportItem?.fare, directionsResult?.currencies)}</nobr></span>}
    </div>
)};

const Markdown = (originString, destinationString, results, transportTypes) => { 

    let origin = originString.split(", ");
    let destination = destinationString.split(", ");
    let relevantResults = {
        agencies: results.agencies,
        currencies: results.currencies,
        suggestedTransports: []
    };
    results.suggestedTransports.forEach((suggestedTransport) => {
        relevantResults.suggestedTransports.push({
            mode: suggestedTransport.mode,
            agency: suggestedTransport.agency,
            start: suggestedTransport.start,
            end: suggestedTransport.end,
            duration: suggestedTransport.duration,
            fare: suggestedTransport.fare,
            suggestedRoute: suggestedTransport.suggestedRoute
        });
    });
    let transportTypesText = TransportTypesQuery(transportTypes, "\n\t\t\t");
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Suggested Transports

The data on the left was retrieved using the following GraphQL query:

> Please note:
> it is now possible to select suggested routes hierarchically linked to a suggested transport
> and / or select all suggested routes.

\`\`\`graphql
{
	easyDirections(
        ...
        arriveBy: false """ defaults to false """
    	date: null      """ format: YYYY-MM-DD (defaults to today) """
    	time: null      """ format: HH:mm (24 hour format, defaults to now) """
        dateTime: null  """ format: ms since 1970 (this field is an alternative to using the date / time parameters; defaults to now) """
        ...
	    from: { coordinates: { 
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        } }
        to: { coordinates:{ 
            latitude: ${destination[1]}
            longitude: ${destination[0]}
        } }
        transportTypes: [
            ${transportTypesText}
        ]
    ) {
        agencies { name }
        currencies { code fractionDigits }
        suggestedTransports {
            mode
            agency { reference, name }
            duration { 
                minimum { minutes } 
                maximum { minutes } 
            }
            fare { 
                unknown
                free
                minimum
                maximum
                currency { reference } 
            }
            """ when clicking on the suggested transport this is the suggested route to use """
            """ note: suggested routes are now referencable """
            suggestedRoute { reference }
            """ selecting the suggested routes here would result in the hierarchically linked suggested routes """
            suggestedRoutes { 
                ...
            }
        }
        """ selecting the suggested routes here would result in all suggested routes """
        suggestedRoutes { 
            ...
        }
    }
}
\`\`\`

The query above produced this result:
\`\`\`json
${relevantJson}
\`\`\`

**Object references:**

Some object types are referencable; they are included as a top level array where the reference field is a numeric index.
The following object types are referenced here:
 * agency
 * currency
 * suggested routes

> Using this method is opt-in and you may choose to include the fields of the referenced objects directly.

**Calculating the fare:**
\`\`\`js
GetFare => ${GetCostString}
\`\`\`

**Calculating the duration:**
\`\`\`js
GetDuration => ${GetDuration}
\`\`\`

**Calculating the travel mode**
\`\`\`js
GetTravelMode => ${GetTravelMode}
\`\`\`

**Looping over the result:**
\`\`\`js
for(let i = 0; i < transports.length; i++) {
${LoopOverResults(results, "\t")}    
}
\`\`\`

---

You will likely run a full query in production, with the suggested routes contained within each suggested transport.

Full query:
\`\`\`graphql
{
	easyDirections(
	    from: { coordinates: { 
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        } }
        to: { coordinates:{ 
            latitude: ${destination[1]}
            longitude: ${destination[0]}
        } }
        transportTypes: [
            ${transportTypesText}
        ]
    ) {
        agencies { name }
        currencies { code fractionDigits }
        suggestedTransports {
            mode
            agency { reference, name }
            duration { 
                minimum { minutes } 
                maximum { minutes } 
            }
            fare { 
                unknown
                free
                minimum
                maximum
                currency { reference } 
            }
            suggestedRoute { reference }
        }
        suggestedRoutes { 
            duration { minutes }
            fare { 
                unknown
                free
                value
                currency { reference } 
            }
            legs { 
                agency { reference, name }
                mode 
                polyline 
                fare { 
                    unknown
                    free
                    value 
                    currency { reference } 
                }
                duration { minutes }
                carbonEmissions
                steps {
                    direction { relative absolute },
                    location {
                        coordinates { latitude longitude},
                        name
                        area
                        bogusName
                    }
                    stayOn
                    distance
                }
            }
        }
    }
}
\`\`\`

Full result:
\`\`\`json
${JSON.stringify(results, null, 4)}
\`\`\`

.
`;};

const SuggestedTransports = ({
    results,
    origin,
    destination,
    suggestedTransports,
    onSelect,
    transportTypes
}) => {
    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        setSelectedIndex(index);
        onSelect(item);
    };

    return (
        suggestedTransports?.length > 0 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(origin, destination, results, transportTypes)} headerText="Suggested Transports" className="directions-suggested-transports-container">
                    { suggestedTransports.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default SuggestedTransports;