import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import polyline from "@mapbox/polyline";

// lines
const reveserLineItem = (item) => [item[1], item[0]];
const reverseLine = (line) => line.map(reveserLineItem);
const DrawLine = (mapState, _line) => {

    if(!_line?.polyline) { 
        console.log(`skipping station: ${JSON.stringify(_line)}`);
        return null; 
    }

    let color;
    switch(_line.type) {
        case "BUS": color = "orange"; break;
        case "TRAM": color = "yellow"; break;
        case "BICYCLE_RENT": color = "green"; break;
        case "PURPLE_POINT": color = "purple"; break;
        default: color = "blue"; break;
    }

    let line = polyline.decode(_line.polyline);
    let sourceId = mapState.addSource({
        type: "geojson",
        data: {
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: reverseLine(line)
            }
        }
    });
    let layer = mapState.addLayer(sourceId, {
        type: "line",
        layout: {
            "line-join": "round",
            "line-cap": "round"
        },
        paint: {
            "line-color": color,
            "line-width": 3
        }
    });
    return {
        color,
        line,
        sourceId,
        layerId: layer.id
    }
};

const ClearLines = (mapState, lines) => {
    lines.forEach((line) => {
        mapState.removeLayer(line.layerId);
        mapState.removeSource(line.sourceId);
    });
};

// constructor
const LinesOnMap = ({
    mapState,
    results,
    selectedLine
}) => {

    let [state, setState] = React.useState({
        results,
        selectedLine
    });

    const ClearStateLines = () => {
        let lines = state.lines;
        if(lines != null) {
            ClearLines(mapState, lines);
        }
    };

    useEffect(() => {
        ClearStateLines();
        if(selectedLine != null) {
            let lines = [selectedLine]
                .map((route) => DrawLine(mapState, route))
                .filter((route) => route != null)
            ;
            setState({
                results: results,
                selectedLine,
    
                lines
            });
        }
    }, [selectedLine]);

    useEffect(() => {

        ClearStateLines();
        let lines;
        if(results?.filtered?.length > 0) {
            lines = results.filtered
                .map((route) => DrawLine(mapState, route))
                .filter((route) => route != null)
            ;
        }  

        setState({
            results: results,
            selectedLine,

            lines
        });

    }, [results]);
    return (
        state.results?.length > 0
        ?   (
                <div key="0"></div>
            )
        : null
    );
};

export default LinesOnMap;