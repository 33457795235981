import React, { useEffect } from 'react';
import ExampleCode from "../ExampleCode/index";
import { TransportTypesQuery } from '../Search/EasyQuery';

const GetDuration = (duration) => {

    let tokens = [];
    if(duration?.formatted != null) {
        if(duration.formatted.hours > 0) { tokens.push(`${duration.formatted.hours} hr`); }
        if(duration.formatted.minutes > 0) { tokens.push(`${duration.formatted.minutes} min`); }
        if(duration.formatted.seconds > 0) { tokens.push(`${duration.formatted.seconds} sec`); }
    }
    return tokens.join(" ");
};

const GetInterval = (interval) => {

    if(interval?.unknown === true) {
        return "Interval Unknown";
    }
    let tokens = [];
    if(interval?.formatted != null) {
        if(interval.formatted.hours > 0) { tokens.push(`${interval.formatted.hours} hr`); }
        if(interval.formatted.minutes > 0) { tokens.push(`${interval.formatted.minutes} min`); }
        if(interval.formatted.seconds > 0) { tokens.push(`${interval.formatted.seconds} sec`); }
    }
    return `Every ${tokens.join(" ")}`;
};

const GetType = (type, agency, agencies) => {
    let result = null;
    let agencyReference = null;
    switch(type) {
        case "BICYCLE_RENT": 
        case "PURPLE_POINT":
            result = agency?.name; 
            agencyReference = agency?.reference;
            break;
        case "BICYCLE": result = "Bike"; break;
        case "BUS": result = "Bus"; break;
        case "WALK": result = "Foot"; break;
        case "CAR": result = "Car"; break;
        case "TRAM": result = "Tram"; break;
        default:
            result = type;
            break;
    }

    if(agencyReference !== null) {
        result = agencies[agencyReference].name;
    }
    return result;
};

const LoopOverResults = (results, tab) => {
    let lines = [];
    
    results.filtered.forEach((_filtered, index) => {

        let station = results.stations[_filtered.reference];

        let result = {
            agency: station.agency,
            type: station.type,
            duration: station.duration,
            location: station.location,
            interval: station.interval,
            additional: station.additional
        };
        lines.push(`${tab}...`);
        
        lines.push(`${tab}/*\n${tab}${JSON.stringify(_filtered, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let filteredItem = filtered[${index}]; // see json above`);

        lines.push(`${tab}/*\n${tab}${JSON.stringify(station, null, 4).replace(/\n/g, "\n" + tab)}*/`);
        lines.push(`${tab}let station = stations[filteredItem.reference]; // let station = stations[${_filtered.reference}], see json above`);
        
        lines.push(`${tab}let icon = station.type; // ${station.type}`);
        lines.push(`${tab}let type = GetType(station.type, station.agency, agencies); // ${GetType(station.type, station.agency, results.agencies)}`);
        lines.push(`${tab}let address = station.location.address; // ${station.location.address}`);
        lines.push(`${tab}let platformLine = station.location.platformLine; // ${station.location.platformLine}`);
        lines.push(`${tab}let duration = GetDuration(station.duration); // ${GetDuration(station.duration)}`);
        lines.push(`${tab}let additionalBicyclesAvailable = station?.additional?.availableBicycles; // ${station?.additional?.availableBicycles}`);
        lines.push(`${tab}let additionalDocksAvailable = station?.additional?.availableDocks; // ${station?.additional?.availableDocks}`);
    });
    return lines.join("\n");
};

let __key=0;
const RenderItems = (onSelect, selectedIndex, results, filterItem,) => {

    let reference = filterItem.reference;
    let stationItem = results.stations[reference];
    let type = GetType(stationItem.type, stationItem?.agency, results.agencies);
    let name = stationItem?.location?.name;
    if(["BICYCLE_RENT","PURPLE_POINT"].indexOf(stationItem.type) >= 0) {
        name = type;
    }

    return (
    <div key={JSON.stringify(stationItem)} className="stations-item" onClick={onSelect.bind(null, stationItem)}>
        <span className="left">
            <nobr>
            icon:<small>{stationItem.type}</small> <b>{type}</b>
            </nobr>
        </span>
        <span className="right">
            <nobr>
            {GetDuration(stationItem.duration)}
            </nobr>
        </span>
        
        {["BUS","TRAM","BICYCLE_RENT","PURPLE_POINT"].indexOf(stationItem.type) >= 0
            ? (<span className="left">{stationItem?.location?.address}</span>) : null
        }
        {["BUS","TRAM","BICYCLE_RENT","PURPLE_POINT"].indexOf(stationItem.type) >= 0
            ? (<span className="right"></span>) : null
        }
        
        {["BUS","TRAM",].indexOf(stationItem.type) >= 0
            ? (<span className="left"><nobr>{stationItem?.location?.platformLine}</nobr></span>) : null
        }
        {["BUS","TRAM",].indexOf(stationItem.type) >= 0
            ? (
                <span className="right">
                    <nobr>{GetInterval(stationItem?.interval)}</nobr>
                </span>
            ) : null
        }

        {["BICYCLE_RENT"].indexOf(stationItem.type) >= 0
            ? (<span className="left">{stationItem?.additional?.availableBicycles} Bicycles</span>) : null
        }
        {["BICYCLE_RENT"].indexOf(stationItem.type) >= 0
            ? (<span className="right">{stationItem?.additional?.availableDocks} Spaces</span>) : null
        }
    </div>
)};

const Markdown = (originString, stationFilter, queryLimits, results) => { 

    let origin = originString.split(", ");
    let relevantResults = {
        agencies: results.agencies,
        filtered: []
    };
    results.filtered.forEach((station) => {
        relevantResults.filtered.push(station);
    });
    let relevantJson = JSON.stringify(relevantResults, null, 4);
    return `
# Stations

The data on the left was retrieved using the following GraphQL query:

\`\`\`graphql
{
    easyStations(
        coordinates: {
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        }${
        (stationFilter?.type ?
        `,
        stationFilter: {
            type: "${stationFilter.type}"
        }`: "")
        }${
        (queryLimits?.radius ?
        `,
        queryLimits: {
            radius: { ${queryLimits.radius_mode === "metric" ? `m: ${queryLimits.radius.m}, km: ${queryLimits.radius.km}` : `ft: ${queryLimits.radius.ft}, mi: ${queryLimits.radius.mi}`} },
            filter: ${queryLimits.filter},
            general: ${queryLimits.general}
        }`
        : "")}
    ) {
        agencies { name }
        filtered { reference }
        stations {
            additional {
                availableBicycles availableDocks
            }
            interval {
                formatted { hours minutes seconds }
                unknown
            }
            agency { reference }
            timetable { ... }
            location {
                name
                platformLine
            }
            distance { ... }
            duration {
              formatted { hours minutes seconds }
            }
            type
        }
    }
}
\`\`\`

The query above produced this result:
> Please note: stations are not included in the JSON below
\`\`\`json
${relevantJson}
\`\`\`

**Object references:**

Some object types are referencable; they are included as a top level array where the reference field is a numeric index.
The following object types are referenced here:
 * agency
 * station
 * filtered

> Using this method is opt-in and you may choose to include the fields of the referenced objects directly.

**Get the type label:**
\`\`\`js
GetType => ${GetType}
\`\`\`

**Calculating the duration:**
\`\`\`js
GetDuration => ${GetDuration}
\`\`\`

**Looping over the result:**
\`\`\`js
for(let i = 0; i < filtered.length; i++) {
${LoopOverResults(results, "\t")}    
}
\`\`\`

---

You will likely run a full query in production, with the suggested routes contained within each suggested transport.

Full query:
\`\`\`graphql
{
    easyStations(
        coordinates: {
            latitude: ${origin[1]}
            longitude: ${origin[0]}
        }${
        (stationFilter?.type ?
        `,
        stationFilter: {
            type: "${stationFilter.type}"
        }`: "")
        }${
        (queryLimits?.radius ?
        `,
        queryLimits: {
            radius: { ${queryLimits.radius_mode === "metric" ? `m: ${queryLimits.radius.m}, km: ${queryLimits.radius.km}` : `ft: ${queryLimits.radius.ft}, mi: ${queryLimits.radius.mi}`} },
            filter: ${queryLimits.filter},
            general: ${queryLimits.general}
        }`
        : "")}
    )   {
            limit { meters filterCount stationCount }
            filter { type }
            agencies { name }
            filtered { reference }
            stations {
                additional {
                    availableBicycles availableDocks lastUpdatedMillis
                }
                interval{
                    formatted { hours minutes seconds }
                    unknown
                }
                agency { reference }
                timetable {
                    departureTime {
                        formatted {
                            hours,
                            minutes,
                            seconds,
                            isPM
                        }
                    }
                    location {
                        name
                    }
                }
                location {
                    name
                    platformLine
                    address
                    coordinates { latitude longitude }
                }
                distance {
                    formatted{ kilometers meters }
                }
                duration {
                    formatted { hours minutes seconds }
                }
                type
            }
        }
    }
}
\`\`\`

Full result:
\`\`\`json
\`\`\`

.
`;};

const Stations = ({
    results,
    origin,
    stationFilter,
    queryLimits,
    onSelect,
}) => {

    let [currentResults, setCurrentResults] = React.useState(results);

    useEffect(() => {
        setCurrentResults(results);
    }, [results]);

    let [selectedIndex, setSelectedIndex] = React.useState(0);
    const onItemSelect = (item, index) => {
        onSelect(item);
    };

    return (
        results?.filtered?.length > 0 
        ?   (
                <ExampleCode showCodeTo="right" markdown={Markdown(origin, stationFilter, queryLimits, results)} headerText="Stations" className="stations-container">
                    { results?.filtered?.map(RenderItems.bind(null, onItemSelect, selectedIndex, results)) }
                </ExampleCode>
            )
        : null
    );
};

export default Stations;