const OTP_SERVER_ENDPOINT = process.env.REACT_APP_OTP_SERVER_ENDPOINT;

const EasyStations = async ({
    coordinates,
    stationFilter,
    queryLimits
}) => {

    let query = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: `
        {
            easyStations(
                ${(coordinates ? 
                    `coordinates: {
                        latitude: ${coordinates.latitude}
                        longitude: ${coordinates.longitude}
                    }`
                : "")}
                ${(coordinates && stationFilter?.type ? ',' : '')}
                ${(stationFilter?.type ? 
                    `stationFilter: {
                        type: "${stationFilter.type}"
                    }`
                : "")}${
                (queryLimits?.radius ?
                    `,
                    queryLimits: {
                        radius: { m: ${queryLimits.radius.m > 0 ? queryLimits.radius.m : 0}, km: ${queryLimits.radius.km > 0 ? queryLimits.radius.km : 0}, ft: ${queryLimits.radius.ft > 0 ? queryLimits.radius.ft : 0}, mi: ${queryLimits.radius.mi > 0 ? queryLimits.radius.mi : 0} },
                        filter: ${queryLimits.filter},
                        general: ${queryLimits.general}
                    }`
                : "")}
            ) {
                limit { meters filterCount stationCount }
                queryLimits {
                    filter
                    general
                    radius { m km ft mi }
                }
                filter { type }
                agencies { name }
                filtered { reference }
                stations {
                    additional {
                        availableBicycles availableDocks lastUpdatedMillis
                    }
                    interval{
                        formatted { hours minutes seconds }
                        unknown
                    }
                    agency { reference }
                    timetable {
                        departureTime {
                            exclude
                            formatted {
                                hours,
                                minutes,
                                seconds,
                                isPM
                            }
                        }
                        location {
                            name
                        }
                    }
                    location {
                        name
                        platformLine
                        address
                        coordinates { latitude longitude }
                    }
                    distance {
                        formatted { kilometers meters }
                    }
                    duration {
                      formatted { hours minutes seconds }
                    }
                    type
                }
            }
        }
        `})
    };

    let response = await fetch(OTP_SERVER_ENDPOINT, query);
    let json = await response.json();
    return json;
};
export { EasyStations };