import React, { useEffect } from 'react';
import Stations from "./Stations.js";
import Timetable from "./Timetable.js";
import Additional from "./Additional.js";
import StationsOnMap from "./StationsOnMap.js";
import FilterTypes from "./FilterTypes.js";
import QueryLimits from "./QueryLimits.js";

const EasyStations = ({
    results,
    origin,
    onSelectStation,
    mapState,
    onFilterType,
    onQueryLimits,
    searchRadius
}) => {

    let [filterType, setFilterType] = React.useState([]);
    const _onFilterType = (filterType) => {
        setFilterType(filterType);
        if(onFilterType) {
            onFilterType(filterType);
        }
    };

    let [queryLimits, setQueryLimits] = React.useState(null);
    const _onQueryLimits = (queryLimits) => {
        setQueryLimits(queryLimits);
        if(onQueryLimits) {
            onQueryLimits(queryLimits);
        }
    };

    const [selectedStation, setSelectedStation] = React.useState(null);
    const _onSelectStation = (station) => {
        setSelectedStation(station);
        if(onSelectStation) {
            onSelectStation(station);
        }
    };

    return (
        <div>
            <QueryLimits onChange={_onQueryLimits} inputRadius={searchRadius}/>
            <FilterTypes onChange={_onFilterType}/>
            <Stations onSelect={_onSelectStation} results={results} origin={origin} stationFilter={filterType} queryLimits={queryLimits}/>
            <Timetable results={results} origin={origin} selectedStation={selectedStation}/>
            <Additional results={results} origin={origin} selectedStation={selectedStation}/>
            <StationsOnMap results={results} mapState={mapState} selectedStation={selectedStation}/>
        </div>
    );
};

export default EasyStations;